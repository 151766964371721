import React from 'react';
import '../../scss/NavigationUpper/HeaderMain.scss';
import CompanyLogo from '../../icons/company-logo.png'
import SmallCompanyLogo from '../../icons/company-logo-small.png'
import SearchBox from './SearchBox.js'
import { withRouter} from "react-router-dom"; // BrowserRouter
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { RiSettings5Fill } from '@react-icons/all-files/ri/RiSettings5Fill';
import { MdShoppingCart } from '@react-icons/all-files/md/MdShoppingCart';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { Link } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openCartMenu } from '../redux/actions/cartMenuAction.js';
import { openLoginView } from '../redux/actions/loginViewAction.js';



class HeaderMain extends React.Component {
  /*constructor() {
    super();
  }*/
  openCartMenu = () => {
    this.props.openCartMenu();
  }
  openLoginView = () => {
    this.props.openLoginView();
  }
  openUserSettings = () => {
    this.props.history.push("/lt/user/profile-settings");
  }

  render() {
    /*
    <ul className = "menu">
      <li><Link to="/lt/kaip-apsipirkti">Kaip pirkti?</Link></li>
      <li><Link to="/lt/apie-mus">Apie mus</Link></li>
      <li><Link to="/lt/kontaktai">Kontaktai</Link></li>
    </ul>
    */
    return (
      <div className = "HeaderMain">
        <div className="companyLogo" onClick = {this.companyLogoClicked}>
          <Link to="/lt"
            className="companyLogoRef"
            aria-label="Į pagrindinį puslapį">
            <img className="companyLogoImg logoImg" alt = "UAB SIRENITAS" aria-label="Į pagrindinį puslapį" src={CompanyLogo} />
          </Link>
          <Link to="/lt"
            className="companyLogoSmallRef"
            aria-label="Į pagrindinį puslapį">
            <img className="companySmallLogoImg logoImg" alt = "UAB SIRENITAS" aria-label="Į pagrindinį puslapį" src={SmallCompanyLogo}  />
          </Link>
        </div>



        <div className = "searchBox">
          <SearchBox />
        </div>
        <div className="userActions">
        {
          this.props.isLoggedIn ?
          <div className="accountIconRef iconRef" onClick = {this.openUserSettings}>
            <div className="accountIconBox iconBox">
              <RiSettings5Fill size = {25}/>
            </div>
            <span className="accountTitle iconTitle">
              Nustatymai
            </span>
          </div>
          :
          <div className="accountIconRef iconRef" onClick = {this.openLoginView}>
            <div className="accountIconBox iconBox">
              <FaUser size = {25}/>
            </div>
            <span className="accountTitle iconTitle">
              Prisijungti
            </span>
          </div>
        }

          <div className="cartIconRef iconRef" onClick = {this.openCartMenu}>
            <div className="cartIconBox iconBox">
              <MdShoppingCart size = {30}/>

              {
                this.props.cartItemsQuantity > 0 &&
                <div className="carQuantity">
                  <span>{this.props.cartItemsQuantity}</span>
                </div>
              }

            </div>
            <span className="cartTitle iconTitle">
            Krepšelis
            </span>
          </div>
          <div className="infoIconRef iconRef">
            <div className="infoIconBox iconBox">
              <Link to="/lt/kontaktai"
                aria-label="Kontaktai">
                <HiOutlineMail size = {30}/>
              </Link>
            </div>
            <span className="infoTitle iconTitle">
              Kontaktai
            </span>
          </div>
        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    cartItemsQuantity: state.cart.quantity,
    isLoggedIn: state.login.isLoggedIn
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      openCartMenu: openCartMenu,
      openLoginView: openLoginView
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(HeaderMain));
//export default connect(mapStateToProps, matchDispatchToProps)(HeaderMain)
