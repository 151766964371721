const initialState = {
    isFetching: false,
    categories: null,
    error: null
}

export const fullCategoriesTreeReducer = function (state = initialState, action) {
  switch (action.type) {
    case "FULL_CATEGOERIES_TREE_START":
      return {
        ...state,
        isFetching: true,
        categories: null,
        error: null
      }
    case "FULL_CATEGOERIES_TREE_SUCCESS":
      return {
        ...state,
        isFetching: false,
        categories: action.fullCategoriesTree,
        error: null
      }
    case "FULL_CATEGOERIES_TREE_ERROR":
      return {
        ...state,
        isFetching: false,
        categories: null,
        error: action.errorMessage
      }
    default:
      return state;
  }
};
