
const initialState = {
    isOpen: false
}

export const cartMenuReducer = function (state = initialState, action) {
  switch (action.type) {
    case "OPEN_CARTMENU":
      return {
        ...state,
        isOpen: true
      }
    case "CLOSE_CARTMENU":
      return {
        ...state,
        isOpen: false
      }
    default:
      return state;
  }
};
