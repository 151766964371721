import React from 'react';
import '../../scss/NavigationUpper/HeaderMobile.scss';
import MobileMenuOpener from './MobileMenuOpener.js'
import SearchBox from './SearchBox.js'



export default class HeaderMobile extends React.Component {


  render() {
    return (

      <div className = "HeaderMobile">
        <div className = "mobileMenu">
          <MobileMenuOpener />
        </div>
        <div className = "searchBox">
          <SearchBox isMobile = {true}/>
        </div>



      </div>
    )
  }
}
