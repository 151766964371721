import React from 'react';
import '../../scss/NavigationUpper/Autocomplete.scss';
import { withRouter, Link } from "react-router-dom";
import fetchAutocomplete from '../data-flow/fetchAutocomplete.js'
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

class Autocomplete extends React.Component {
  constructor() {
    super();
    this._isMounted = false;

    this.state = {
      products: [],
      timeOutFunction: 0
    };

  }
  componentDidMount() {
    this._isMounted = true;
    this.onTextChanged();
  }
  componentWillUnmount() {
   this._isMounted = false;
}

  componentDidUpdate(prevProps) {
    if(prevProps.searchText != this.props.searchText) {
      this.onTextChanged();
    }
  }
  onTextChanged() {
    if (this.state.timeOutFunction) {
       //console.log("clearing");
       clearTimeout(this.state.timeOutFunction);
    }
    let self = this;
    this.setState({
      timeOutFunction: setTimeout(function () {
        fetchAutocomplete(self.props.searchText,
          self.fetchAutocompleteOnSuccess,
          self.fetchAutocompleteOnError);

        ReactGA.event({
          category: 'engagement',
          action: 'autocomplete',
          label: self.props.searchText
        });
        ReactGA4.event(
          "autocomplete",
          {
            search_phrase: self.props.searchText
          }
        );
      }, 400)
    });
  }

  /*setAutocompleteState = (products) => {
    if(this._isMounted) {
      this.setState({
        products: products
      });
    }
  }*/

  fetchAutocompleteOnSuccess = (data) => {
    if(this._isMounted) {
      this.setState({
        products: data
      });
    }
  }
  fetchAutocompleteOnError = (response) => {
  }


  render() {
    //console.log(this.props.searchText);this.props.isShow
    return (
      <>
      {
        this.state.products.length > 0 ?
        <div className = "Autocomplete" ref={this.props.forwardedRef}>
          <div className = "products">
            {
              this.state.products?.map((product, index) => (
                <Link className = "product"
                  key = {index}
                  tabIndex="-1"
                  to = {product.url}
                  onClick = {this.props.closeAutocomplete}
                >
                  <div className = "productInner">
                    <div className = "photo">
                      <img
                        width = {75}
                        src = {product.imageName} />
                    </div>
                    <div className = "details">
                      <div className = "title" dangerouslySetInnerHTML={{__html: product.title}}>
                      </div>
                      <div className = "codePrice">
                        <div className = "code">
                          {product.code}
                        </div>
                        <div className = "price">
                          {product.price.toFixed(2).replace('.', ',')} €
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.products.length - 1 != index ?
                    <hr />
                    :
                    null
                  }

                </Link>
              ))
            }

          </div>
        </div>
        :
        null
      }

      </>
    )
  }
}
//export default Autocomplete;
export default withRouter(Autocomplete);
