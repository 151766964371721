import React from 'react';
import '../../scss/Notifications/Notification.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideNotification} from '../redux/actions/notificationAction.js';

class Notification extends React.Component {
  constructor() {
    super();
  }

  hideAlert = () => {
    this.props.hideNotification();
  }

  render() {
    let className = this.props.notificationIsShown ? "showAlert" : "hideAlert";

    return (
      <div className = {`Notification ${className}`} onTransitionEnd = {this.hideAlert}>
        <div>
          <h3 className = "title">{this.props.notificationTitle}</h3>
        </div>
        <div className = "content">
          <span>{this.props.notificationMessage}</span>
        </div>
      </div>
    )

  }
}

function mapStateToProps(state) {
  return {
    notificationIsShown: state.notification.isShown,
    notificationTitle: state.notification.title,
    notificationMessage: state.notification.message
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      hideNotification: hideNotification
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Notification)
//export default Notification;
