import React from 'react';
import '../../scss/Login/Login.scss';
import { FaExclamation } from '@react-icons/all-files/fa/FaExclamation';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { BiShow } from '@react-icons/all-files/bi/BiShow';
import { BiHide } from '@react-icons/all-files/bi/BiHide';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from "react-router-dom";
import { closeLoginView } from './../redux/actions/loginViewAction.js';
import { loginSucceeded } from './../redux/actions/loginAction.js';
import { openRemindPasswordView } from './../redux/actions/remindPasswordViewAction.js';
import login from '../data-flow/login.js'

class Login extends React.Component {

constructor() {
  super();

  this.state = {
    isShowPassword: false,
    isIncorrectUsernameOrPasswordMessage: false
  };
}

changeShowPasswordStatus(isShowPassword) {
  this.setState({
    isShowPassword: isShowPassword
  });
}
showPassword = () => {
  this.changeShowPasswordStatus(true);
}
hidePassword = () => {
  this.changeShowPasswordStatus(false);
}
changeErrorMessageStatus(isErrorMessage) {
  this.setState({
    isIncorrectUsernameOrPasswordMessage: isErrorMessage
  });
}
showErrorMessage() {
  if(!this.state.isIncorrectUsernameOrPasswordMessage) {
    this.changeErrorMessageStatus(true);
  }
}
hideErrorMessage = () => {
  if(this.state.isIncorrectUsernameOrPasswordMessage) {
    this.changeErrorMessageStatus(false);
  }
}
openRemindPasswordView = () => {
  this.props.closeLoginView();
  this.props.openRemindPasswordView();
}
submitForm = (e) => {
  e.preventDefault();

  let email = e.target.email.value;
  let password = e.target.password.value;
  login(email, password, this.loginOnSuccess, this.loginOnError);
}

  loginOnSuccess = (data) => {
    let name = data;
    this.props.loginSucceeded(name);
    this.props.closeLoginView();
    //this.props.history.push("/");
  }
  loginOnError = (response) => {
    if(response && response.status == 401) {
      this.showErrorMessage();
    }
    /*if(response && response.status == 400) {
      let inputErrors = response.data;
    }*/
  }

  render() {
    //let passwordType = "password";
    //console.log("aaa");
    let passwordType = this.state.isShowPassword ? "text" : "password";

    return (
      <div className="Login" ref = {this.props.wrapperRef}>
        <div className="header">
            <h2 className="title">Prisijungimas</h2>
            <div className="close" onClick = {this.props.closeLoginView}>
              <FaTimes size = {40}/>
            </div>
        </div>
        <form className="body" onSubmit = {this.submitForm}>
          <div className="credentials">
            {
              this.state.isIncorrectUsernameOrPasswordMessage ?
              <div className="errorMessage">
                <span>
                  <FaExclamation size = {16}/>
                  <span>Neteisingas el. paštas arba slaptažodis</span>
                </span>
              </div>
              :
              <div className="errorMessage"></div>
            }

            <div className="username credential">
              <span>El. paštas</span>
              <input
                type="text"
                name="email"
                autoComplete="email"
                onFocus = {this.hideErrorMessage}
                />
            </div>
            <div className="password credential">
              <span>Slaptažodis</span>
              <div className="passwordDiv">
                <input
                  type={`${passwordType}`}
                  name="password"
                  className="password"
                  autoComplete="password"
                  onFocus = {this.hideErrorMessage}
                   />

                {
                  this.state.isShowPassword ?
                  <div className="hidePsw" onClick = {this.hidePassword}>
                    <BiHide size = {25}/>
                  </div>
                  :
                  <div className="showPsw" onClick = {this.showPassword}>
                    <BiShow size = {25}/>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="forgotPassword" onClick = {this.openRemindPasswordView}>
              Pamiršau slaptažodį
          </div>
          <div className="loginButton">
            <button type = "submit">Prisijungti</button>
          </div>
        </form>
      </div>
    )
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeLoginView: closeLoginView,
      loginSucceeded: loginSucceeded,
      openRemindPasswordView: openRemindPasswordView
    }, dispatch);
}

//export default connect(null, matchDispatchToProps)(Login)
export default withRouter(connect(null, matchDispatchToProps)(Login));
//export default Login
