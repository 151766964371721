import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function fetchAllCategoriesList(onSuccess, onError) {
  axiosInstance.get(`${server}/Categories/GetAllCategoriesList`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchAllCategoriesList;
