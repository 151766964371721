import React from 'react';
import { Link } from "react-router-dom";
import '../../scss/NavigationUpper/HeaderSecondary.scss';
import RootCategories from './RootCategories.js';
import CategoriesSearch from './CategoriesSearch.js';
import { MdFilterList } from '@react-icons/all-files/md/MdFilterList'; //CgMenuGridR
//import fetchCategories from '../data-flow/fetchCategories.js'
import InfoIcon from 'Root/icons/info_icon.png';
import NewProductsIcon from 'Root/icons/new_products_icon.png';
import AboutUsIcon from 'Root/icons/building-people-icon.png';
import ContactsUsIcon from 'Root/icons/phone-mail-icon.png';


const HeaderSecondary = () => {
  return (
    <div className="HeaderSecondary">
      <div className="mainMenu">
        <CategoriesSearch />
        <ul className="menu">
          <MenuItem to="/lt/kaip-apsipirkti" icon={<IconWithMargin src={InfoIcon} alt="Kaip pirkti ikona" />}>
            Kaip pirkti?
          </MenuItem>
          <MenuItem to="/lt/naujienos" icon={<IconWithMargin src={NewProductsIcon} alt="Naujienos ikona" />}>
            Naujienos
          </MenuItem>
          <MenuItem to="/lt/apie-mus" icon={<IconWithMargin src={AboutUsIcon} alt="Apie mus ikona" />}>
            Apie mus
          </MenuItem>
          <MenuItem to="/lt/kontaktai" icon={<IconWithMargin src={ContactsUsIcon} alt="Kontaktai ikona" />}>
            Kontaktai
          </MenuItem>
        </ul>
      </div>
    </div>
  );
};

const IconWithMargin = ({ src, alt }) => (
  <img className ="icon" src={src} width={20} height={20} alt={alt} />
);

const MenuItem = ({ to, icon, children }) => (
  <li>
    <Link to={to}>
      {icon}
      <span>
        {children}
      </span>
    </Link>
  </li>
);

export default HeaderSecondary;
