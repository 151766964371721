import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';
import axios from 'axios';
let isLoginStatusPending = false;

function loginStatus(onSuccess, onError) {
  if(isLoginStatusPending) return;
  isLoginStatusPending = true;

  axios.get(`${server}/Account/LoginStatus`, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    })
    .finally(() => {
      isLoginStatusPending = false;
    });
}

export default loginStatus;

/*
User'iui darant puslapio refresh tikrinama ar user'is prisijunges

Response 200: Jonas Petraitis

Response 401 (error) ?

*/
