export const setCartQuantity = (quantity) => ({
  type: 'SET_CART_QUANTITY',
  quantity: quantity
})
export const addCartQuantity = (quantity) => ({
  type: 'ADD_CART_QUANTITY',
  quantity: quantity
})
export const decreaseCartQuantity = (quantity) => ({
  type: 'DECREASE_CART_QUANTITY',
  quantity: quantity
})
export const removeCartQuantity = () => ({
  type: 'REMOVE_CART_QUANTITY'
})

export const showAddedToCartNotification = (product, quantity) => ({
  type: 'SHOW_ADDEDTO_CART_NOTI',
  productShown: product,
  productQuantityShown: quantity
})

export const hideAddedToCartNotification = () => ({
  type: 'HIDE_ADDEDTO_CART_NOTI'
})

/*export const closeCartMenu = () => ({
  type: 'CLOSE_CARTMENU'
})*/
