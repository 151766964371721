import React from 'react';
import '../../scss/NavigationUpper/NavigationUpper.scss';
import HeaderMain from './HeaderMain.js';
import HeaderSecondary from './HeaderSecondary.js';
import HeaderMobile from './HeaderMobile.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from "react-router-dom";
import { logoutSucceeded } from './../redux/actions/loginAction.js';
import logout from '../data-flow/logout.js'
import fetchRunningBox from '../data-flow/MenuData/fetchRunningBox.js'

class NavigationUpper extends React.Component {

  constructor() {
    super();

    this.state = {
      runningBoxData: "",
    };
  }

  componentDidMount() {
    fetchRunningBox(this.fetchRunningBoxOnSuccess, this.fetchRunningBoxOnError);
  }
  fetchRunningBoxOnSuccess = (data) => {
    this.setState({
      runningBoxData: data
    });
  }
  fetchRunningBoxOnError = (response) => {
  }

  logout = () => {
    logout(this.logoutOnSuccess, this.logoutOnError);
  }
  logoutOnSuccess = (data) => {
    this.props.logoutSucceeded();
    //this.props.history.push("/lt");
  }
  logoutOnError = (response) => {
    let error = response.data;
    /*if(response && response.status == 400) {
      let error = response.data;
    }*/
  }

  render() {

    return (
      <div className = "NavigationUpper">
        <div className = "runnerBox">
          <div className = "runnerBoxContainer">
            <span className="scroll-text">
              {this.state.runningBoxData}
            </span>
          </div>
        </div>

        {
          this.props.isLoggedIn ?
          <div className = "user">
            <span>{this.props.loggedPersonName}</span>
            <span>|</span>
            <span className = "logout" onClick = {this.logout}>Atsijungti</span>
          </div>
          :
          <div className = "user"></div>
        }


        <div className = "NavigationUpperNormal">
          <HeaderMain />
          <HeaderSecondary />
        </div>
        <div className = "NavigationUpperMobile">
          <HeaderMobile />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.login.isLoggedIn,
    loggedPersonName: state.login.personName
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      logoutSucceeded: logoutSucceeded
    }, dispatch);
}

//export default connect(mapStateToProps, matchDispatchToProps)(NavigationUpper)
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(NavigationUpper));
//export default NavigationUpper;
