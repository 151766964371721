import React from 'react';
import { withRouter } from "react-router-dom";
import '../scss/ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // use lifecycle to catch errors in any components below and re-render fallback UI
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  componentDidUpdate(prevProps) {
    let oldLocation = prevProps.location.pathname;
    let location = this.props.location.pathname;
    if(oldLocation != location) {
      this.setState({
        error: null,
        errorInfo: null,
      });
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Fallback UI
      return (
        <div className = "ErrorBoundary">
          <h2>Puslapio klaida</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)
