import fetchAllCategoriesList from 'Root/js/data-flow/fetchAllCategoriesList.js';
import { allCategoriesListStart, allCategoriesListSuccess, allCategoriesListError }
  from 'Root/js/redux/actions/allCategoriesListAction.js';


export const allCategoriesListThunk = () => {
  return (dispatch, getState) => {
    const state = getState().allCategoriesList;
    //console.log(state);
    if(!state.isFetching && !state.categories) {
      dispatch(allCategoriesListStart);

      fetchAllCategoriesList(
        data => allCategoriesListOnSuccess(dispatch, data),
        response => allCategoriesListOnError(dispatch, response)
      );
    }
  }
}

function allCategoriesListOnSuccess (dispatch, data) {
  dispatch(allCategoriesListSuccess(data));
}
function allCategoriesListOnError(dispatch, response) {
  dispatch(allCategoriesListError(response));
}
