
const initialState = {
    isShown: false,
    title: null,
    message: null
}

export const notificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case "SHOW_NOTI":
      return {
        ...state,
        isShown: true,
        title: action.title,
        message: action.message
      }
    case "HIDE_NOTI":
      return {
        ...state,
        isShown: false
      }
    default:
      return state;
  }
};
