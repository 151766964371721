import React from 'react';
import '../scss/WebsiteUnavailable.scss';
import LoadingSpinner from '../icons/loading-spinner.svg';


class WebsiteUnavailable extends React.Component {

  render() {
    return (
      <div className = "WebsiteUnavailable">
        <div className = "spinner">
          <img className="loadingSpinner" src={LoadingSpinner} />
          <div className="pleaseWait">
            <span>Prašome palaukti...</span>
          </div>
        </div>
        <div className = "title">
          {this.props.error.title}
        </div>
        <div className = "message">
          {this.props.error.message}
        </div>

      </div>
    )
  }
}



export default WebsiteUnavailable
