export const fullCategoriesTreeStart = () => ({
  type: 'FULL_CATEGOERIES_TREE_START'
})

export const fullCategoriesTreeSuccess = (fullCategoriesTree) => ({
  type: 'FULL_CATEGOERIES_TREE_SUCCESS',
  fullCategoriesTree: fullCategoriesTree
})

export const fullCategoriesTreeError = (error) => ({
  type: 'FULL_CATEGOERIES_TREE_ERROR',
  errorMessage: error
})
