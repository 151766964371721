const initialState = {
    isFetching: false,
    categories: null,
    error: null
}

export const allCategoriesListReducer = function (state = initialState, action) {
  switch (action.type) {
    case "ALL_CATEGOERIES_LIST_START":
      return {
        ...state,
        isFetching: true,
        categories: null,
        error: null
      }
    case "ALL_CATEGOERIES_LIST_SUCCESS":
      return {
        ...state,
        isFetching: false,
        categories: action.allCategoriesList,
        error: null
      }
    case "ALL_CATEGOERIES_LIST_ERROR":
      return {
        ...state,
        isFetching: false,
        categories: null,
        error: action.errorMessage
      }
    default:
      return state;
  }
};
