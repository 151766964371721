import React from 'react';
import '../../scss/Content/RfqView.scss';
import { FaExclamation } from '@react-icons/all-files/fa/FaExclamation';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { BiShow } from '@react-icons/all-files/bi/BiShow';
import { BiHide } from '@react-icons/all-files/bi/BiHide';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeRfqView } from '../redux/actions/rfqViewAction.js';
import requestForQuotation from '../data-flow/requestForQuotation.js';
import { showNotification} from '../redux/actions/notificationAction.js';


class RfqView extends React.Component {

constructor() {
  super();

  this.state = {
    errors: {}
  }
}

removeErrors = () => {
  this.setState({
    errors: {}
  });
}

submitForm = (event) => {
  event.preventDefault();
  let productId = this.props.product.id;
  let email = event.target.email.value;
  let message = event.target.message.value;

  requestForQuotation(productId, email, message, this.requestForQuotationOnSuccess, this.requestForQuotationOnError);
}
requestForQuotationOnSuccess = (data) => {
  this.props.showNotification("", "Užklausa dėl prekės sėkmingai išsiųsta !");
  this.props.closeRfqView();
}
requestForQuotationOnError = (response) => {
  if(response && response.status == 400) {
    let inputErrors = response.data;
    this.setState({
      errors: inputErrors
    });
  }
}


  render() {
    //console.log(this.props);
    return (
      <div className="RfqView" ref = {this.props.wrapperRef}>
        <div className="header">
          <h2 className="title">Teirautis dėl užsakymo</h2>
          <div className="close" onClick = {this.props.closeRfqView}>
            <FaTimes size = {40}/>
          </div>
        </div>
        <div className="body">
          <div className="productName">
            <h5>{this.props.product.name}</h5>
          </div>
          <form className="rfqForm" onSubmit = {this.submitForm}>
            <div className="credentials">
              <div className="email credential">
                <span>El. paštas</span>
                {
                  this.state.errors.email &&
                  <span className = "errorMessage">{this.state.errors.email}</span>
                }
                <input
                  className = "input"
                  type="text"
                  onFocus = {this.removeErrors}
                  name="email"
                  autoComplete="email"
                  />
              </div>
              <div className="message credential">
                <span>Klausimas</span>
                {
                  this.state.errors.message &&
                  <span className = "errorMessage">{this.state.errors.message}</span>
                }
                <textarea className = "input"
                  onFocus = {this.removeErrors}
                  name = "message">
                </textarea>
              </div>
            </div>
            <div className="loginButton">
              <button type = "submit">Siųsti</button>
            </div>
          </form>
        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    product: state.rfqView.product
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeRfqView: closeRfqView,
      showNotification: showNotification
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(RfqView)
