//import { Navigate } from "react-router-dom";
import React from "react";
import { useHistory } from "react-router-dom";

const Protected = ({ isLoggedIn, children }) => {
  const history = useHistory();
  if (!isLoggedIn) {
    history.replace("/");
  }
  return children;
};
export default Protected;
