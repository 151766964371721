import 'core-js/actual';
import React from 'react';
import ReactDOM from 'react-dom';
import '../scss/fonts.scss';
import App from './App';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { injectStore } from './data-flow/_config.js'
import { productsReducer } from './redux/reducers/productsReducer.js';
import { mobileMenuReducer } from './redux/reducers/mobileMenuReducer.js';
import { cartMenuReducer } from './redux/reducers/cartMenuReducer.js';
import { mainMenuReducer } from './redux/reducers/mainMenuReducer.js';
import { cartReducer } from './redux/reducers/cartReducer.js';
import { loginViewReducer } from './redux/reducers/loginViewReducer.js';
import { loginReducer } from './redux/reducers/loginReducer.js';
import { remindPasswordViewReducer } from './redux/reducers/remindPasswordViewReducer.js';
import { rfqViewReducer } from './redux/reducers/rfqViewReducer.js';
import { notificationReducer } from './redux/reducers/notificationReducer.js';
import { homePageReducer } from './redux/reducers/homePageReducer.js';
import { allCategoriesListReducer } from './redux/reducers/allCategoriesListReducer.js';
import { fullCategoriesTreeReducer } from './redux/reducers/fullCategoriesTreeReducer.js';;


const middlewares = [thunk];
let rootReducer = combineReducers({
  products: productsReducer,
  mobileMenu: mobileMenuReducer,
  cartMenu: cartMenuReducer,
  mainMenu: mainMenuReducer,
  cart: cartReducer,
  loginView: loginViewReducer,
  login: loginReducer,
  remindPasswordView: remindPasswordViewReducer,
  rfqView: rfqViewReducer,
  notification: notificationReducer,
  homePage: homePageReducer,
  allCategoriesList: allCategoriesListReducer,
  fullCategoriesTree: fullCategoriesTreeReducer
});
let store = createStore(rootReducer, applyMiddleware(...middlewares));
injectStore(store);

ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
  document.getElementById('root')
);
