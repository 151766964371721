import React from 'react';
import '../../scss/NavigationUpper/MobileMenuOpener.scss';
import { MdMenu } from '@react-icons/all-files/md/MdMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openMobileMenu } from '../redux/actions/mobileMenuAction.js';



class MobileMenuOpener extends React.Component {

  componentDidMount() {
    //console.log(this.props);
  }

  mobileMenuButtonClicked = (event) => {
      this.props.openMobileMenu();
  }

  render() {
    return (

      <div className = "MobileMenuOpener">
        <a className="mobileMenuIconRef">
          <button className="mobileMenuIconBox"
            onClick = {this.mobileMenuButtonClicked}
            aria-label="Kategorijų meniu"
          >
            <MdMenu size = {40} viewBox = "3 5.5 18 12"/>

          </button>
        </a>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuIsOpen: state.mobileMenu.isOpen
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      openMobileMenu: openMobileMenu
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(MobileMenuOpener)
