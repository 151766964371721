const initialState = {
    isPageOpen: false
}

export const homePageReducer = function (state = initialState, action) {
  switch (action.type) {
    case "OPEN_HOME_PAGE":
      return {
        ...state,
        isPageOpen: true
      }
    case "CLOSE_HOME_PAGE":
      return {
        ...state,
        isPageOpen: false
      }
    default:
      return state;
  }
};
