import React from 'react';
import '../../scss/CartMenu/CartMenu.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeCartMenu } from '../redux/actions/cartMenuAction.js';
import { setCartQuantity } from '../redux/actions/cartAction.js';
import CartMenuProduct from './CartMenuProduct.js';
import CloseIcon from '../../icons/close.svg';
import ShoppingCartIcon from '../../icons/shopping-cart.png';
import { AiOutlineExpand } from '@react-icons/all-files/ai/AiOutlineExpand';
import { Link, withRouter } from "react-router-dom";
import fetchCartItems from '../data-flow/fetchCartItems.js';
import fetchOrderMinimumValue from '../data-flow/fetchOrderMinimumValue.js';
import removeCartProduct from '../data-flow/removeCartProduct.js';

class CartMenu extends React.Component {

  constructor() {
    super();

    this.state = {
        orderMinimumValue: 0,
        isFetchedOrderMinimumValue: false,
        products: [],
        productsCostWithoutVat: 0,
        productsVat: 0,
        productsCostWithVat: 0

    };

  }

  componentDidMount() {
    fetchOrderMinimumValue(this.fetchOrderMinimumValueOnSuccess, this.fetchOrderMinimumValueOnError);
    fetchCartItems(this.fetchCartItemsOnSuccess, this.fetchCartItemsOnError);

  }
  componentDidUpdate(prevProps) {
    if(!prevProps.cartMenuIsOpen && this.props.cartMenuIsOpen) {
      fetchCartItems(this.fetchCartItemsOnSuccess, this.fetchCartItemsOnError);
    }
    let pathname = this.props.location.pathname;
    let orderWindowIsOpened = pathname.includes("/lt/order/") ? true : false;
    if(orderWindowIsOpened) {
      this.props.closeCartMenu();
    }
  }

  closeCartMenu = (event) => {
      //console.log(this.props);
      this.props.closeCartMenu();
  }

  /*setCartDataState = (data) => {
    this.setState({
      products: data.cartProducts,
      //vat: data.vat
      productsCostWithoutVat: data.productsCostWithoutVat,
      productsVat: data.productsVat,
      productsCostWithVat: data.productsCostWithVat
    });
  }*/

  fetchCartItemsOnSuccess = (data) => {
    this.setCartData(data);
  }

  setCartData = (data) => {
    this.setState({
      products: data.cartProducts,
      productsCostWithoutVat: data.productsCostWithoutVat,
      productsVat: data.productsVat,
      productsCostWithVat: data.productsCostWithVat
    });
    this.props.setCartQuantity(data.allAmount ?? 0);
  }

  fetchCartItemsOnError = (response) => {
  }

  fetchOrderMinimumValueOnSuccess = (data) => {
    this.setState({
      isFetchedOrderMinimumValue: true,
      orderMinimumValue: data,
    });
  }

  fetchOrderMinimumValueOnError = (response) => {
  }

  removeCartItem = (product) => {
    removeCartProduct(product.productId, product.isSupplierProduct,
      this.removeCartProductOnSuccess, this.removeCartProductOnError);
  }

  removeCartProductOnSuccess = (data) => {
    this.setCartData(data);
  }
  removeCartProductOnError = (response) => {
  }

  render() {
    //console.log(this.props);
    let pathname = this.props.location.pathname;
    let orderWindowIsOpened = pathname.includes("/lt/order/") ? true : false;
    let showOrderValueTooLowInfo = this.state.isFetchedOrderMinimumValue && this.state.productsCostWithVat < this.state.orderMinimumValue;

    return (
      <>
      {
        this.props.cartMenuIsOpen && !orderWindowIsOpened &&
          <div className = "CartMenu">
            <div className = "cartOverlay" onClick = {this.closeCartMenu}></div>
            <div className = "cartMenu">
              <div className = "header">
                <div className = "headerTitle">
                  <span>
                    <h2>Krepšelis</h2>
                    {
                      this.props.totalQuantity && this.props.totalQuantity > 0 ?
                      <span>({this.props.totalQuantity} prekės)</span>
                      :
                      null
                    }

                  </span>
                </div>
                <div className = "controls">
                 {
                   this.props.totalQuantity > 0 ?
                   <div
                     className = "headerExpand"
                     title = "Detali peržiūra"
                   >
                     <Link to="/lt/order/list" onClick = {this.closeCartMenu}>
                       <AiOutlineExpand size = {40} />
                     </Link>
                   </div>
                   :
                   null
                 }

                  <div
                    className = "headerClose"
                    onClick = {this.closeCartMenu}
                    title = "Uždaryti"
                    >
                    <img className="closeIconImg" alt = "Uždaryti" src={CloseIcon} />
                  </div>
                </div>
              </div>
              <div className = "body">
              { !this.props.totalQuantity || this.props.totalQuantity == 0 ?
                <div className = "info">
                  <div className = "image">
                    <img className="shoppingCartIconImg"
                      alt = "Pirkinių krepšelis"
                      src={ShoppingCartIcon}
                    />
                  </div>
                  <div className = "infoText">
                    <h3>Jūsų krepšelis tuščias</h3>
                  </div>
                  <div className = "searchProducts">
                    <Link className = "searchProductsLink" to = "/" onClick = {this.props.closeCartMenu}>
                      Ieškoti prekių
                    </Link>
                  </div>
                </div>
                :
                <div className = "productsInfo">
                  <div className = "productsListing">
                    {
                      this.state.products?.map((product, index) => (
                        <CartMenuProduct
                          key = {product.productId}
                          product = {product}
                          removeCartItem = {(product) => this.removeCartItem(product)}
                          />
                      ))
                    }


                  </div>

                  <div className = "finances">
                    <div className = "item productCostWithoutVAT">
                      <span className = "title">Prekių kaina:</span>
                      <span className = "data">{this.state.productsCostWithoutVat.toFixed(2).replace('.', ',')} €</span>
                    </div>
                    <div className = "item productCostVAT">
                      <span className = "title">PVM:</span>
                      <span className = "data">{this.state.productsVat.toFixed(2).replace('.', ',')} €</span>
                    </div>
                    <div className = "item productCostWithVAT">
                      <span className = "title">Prekių kaina su PVM:</span>
                      <span className = "data">{this.state.productsCostWithVat.toFixed(2).replace('.', ',')} €</span>
                    </div>
                  </div>

                  {
                    showOrderValueTooLowInfo &&
                    <div className = "minOrderValueWarning">
                      Norint tęsti pirkimą, krepšelio minimali suma turi būti {this.state.orderMinimumValue} €
                    </div>
                  }
                  <div className = "buttons">
                    <div className="startBuying">
                      <Link to="/lt/order/list" onClick = {this.closeCartMenu}>
                        Detali peržiūra
                      </Link>
                    </div>
                    <div>
                      <Link to="/lt/order/order-info"
                        onClick = {this.closeCartMenu}
                        className={showOrderValueTooLowInfo ? "disabledButton" : ""}>
                        Atlikti užsakymą
                      </Link>
                    </div>
                  </div>

                </div>
                }
              </div>
            </div>
          </div>

      }
      </>

    )
  }
}

function mapStateToProps(state) {
  return {
    cartMenuIsOpen: state.cartMenu.isOpen,
    totalQuantity: state.cart.quantity
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeCartMenu: closeCartMenu,
      setCartQuantity: setCartQuantity
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CartMenu));
//export default connect(mapStateToProps, matchDispatchToProps)(CartMenu)
