import { server, axiosInstance } from "./_config.js"

function fetchCartItems(onSuccess, onError) {
  axiosInstance.get(`${server}/Products/GetProductsInCart`, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchCartItems;

/*
Response:
{
"cartProducts":[{"productId":"8626","code":"LIB14250ERCNA","name":"Baterija li\u010Dio 3.6V 1/2AA 14.3x24,6mm lituoj1.1Ah","price":4.92,"quantityAdded":2.0000,"unit":"vnt","isSupplierProduct":0,"imageRef":"https://www.sirenitas.lt/get_image.php?size=80\u0026path=PRODUCTS\u0026img=1_LIB14250ERCNA.jpg","deliveryTimeDays":0,"minAmount":1},{"productId":"8917","code":"LIB14500LIT","name":"Baterija li\u010Dio 3.6V 2400mAh 14.5x50mm XL-060F","price":5.90,"quantityAdded":1.0000,"unit":"vnt","isSupplierProduct":0,"imageRef":"https://www.sirenitas.lt/get_image.php?size=80\u0026path=PRODUCTS\u0026img=1_LIB14500LIT.jpg","deliveryTimeDays":0,"minAmount":1},{"productId":"8918","code":"LSH14     ","name":"Baterija R14 (C) 3.6V 5800mAh 50x26.30mm Saft Li-ion","price":17.50,"quantityAdded":1.0000,"unit":"vnt","isSupplierProduct":0,"imageRef":"https://www.sirenitas.lt/get_image.php?size=80\u0026path=PRODUCTS\u0026img=27661__27589__1163335565.jpg","deliveryTimeDays":0,"minAmount":1}],

"productsCostWithoutVat":26.26,"productsVat":6.98,"productsCostWithVat":33.240000
}

*/
