import React from 'react';
//import '../../scss/NavigationUpper/HeaderSecondary.scss';
import SubSubCategory from './SubSubCategory.js';
import { Link } from "react-router-dom";
import { BiRightArrow } from '@react-icons/all-files/bi/BiRightArrow';
//import { AddCategoryQueryParam } from '../tools/urlBuilder.js'

export default class SubCategory extends React.Component {

  constructor() {
    super();

    this.state = {
      maxVisibleSubSubCategories: 5,
      showAllSubSubCategories: false
    };

  }
  componentDidMount() {
    //console.log(this.props);
  }

  showAllSubSubCategories = () => {
    this.setState({
      showAllSubSubCategories: true
    });
  }
  hideSomeSubSubCategories = () => {
    this.setState({
      showAllSubSubCategories: false
    });
  }


  render() {
    return (
      <div className = "subCat">

        <div className = "subCatName">
          <BiRightArrow size = {15}/>
          <Link to={{
            pathname: `/lt/products/${this.props.data.url}`
          }}
            onClick = {e => {
              this.props.switchMegaMenuVisibility(false, true);
            }}
            >
            {this.props.data.name}
          </Link>
        </div>

        <ul className = "subSubCategories">
          {this.props.data?.sub && this.props.data.sub?.map((subSubCategory, index) => (
            (index < this.state.maxVisibleSubSubCategories) || this.state.showAllSubSubCategories ?
              <SubSubCategory
                key={subSubCategory.id}
                data = {subSubCategory}
                switchMegaMenuVisibility = {this.props.switchMegaMenuVisibility}
              /> : null
          ))}
          {
            this.props.data?.sub?.length > this.state.maxVisibleSubSubCategories &&  !this.state.showAllSubSubCategories ?
              <li className = "subSubCat showMoreLess" onClick = {this.showAllSubSubCategories}>
                <a>Daugiau...</a>
              </li> : null
          }
          {
            this.props.data?.sub?.length > this.state.maxVisibleSubSubCategories &&  this.state.showAllSubSubCategories ?
              <li className = "subSubCat showMoreLess" onClick = {this.hideSomeSubSubCategories}>
                <a>Mažiau...</a>
              </li> : null
          }


        </ul>
      </div>
    )
  }
}
