import React from 'react';
import '../../scss/NavigationUpper/SearchBox.scss';
import { withRouter } from "react-router-dom";
//import MagnifierGlassIcon from '../../icons/magnifierGlass.png'
//import { BsSearch } from 'react-icons/bs';
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import Autocomplete from './Autocomplete.js'
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import qs from 'query-string';

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.autoCompleteWrapperRef = React.createRef();
    this.searchInputWrapperRef = React.createRef();

    let searchTextFromUrl = this.getCurrentSearchTextFromUrl(props);
    //console.log(searchTextFromUrl);

    this.state = {
      searchText: searchTextFromUrl || "",
      isShowAutocomplete: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideAutocomplete);

    /*this.setState({
      searchText: event.target.value
    });*/
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideAutocomplete);
  }
  componentDidUpdate(prevProps) {
    let prevSearchTextFromUrl = this.getCurrentSearchTextFromUrl(prevProps);
    let currentSearchTextFromUrl = this.getCurrentSearchTextFromUrl(this.props);
    if(prevSearchTextFromUrl != currentSearchTextFromUrl) {
      this.setState({
        searchText: currentSearchTextFromUrl || "",
      });
    }
  }


  getCurrentSearchTextFromUrl = (props) => {
    let queryString = props.location.search;
    let queryData = qs.parse(queryString);
    return queryData.q;
  }

  searchTextChanged = (e) => {
    //console.log(e.target.value);
    this.setState({
      searchText: e.target.value,
      isShowAutocomplete: true
    });
  }
  searchProducts = (e) => {
    //console.log(this.state.searchText);
    e.preventDefault();
    this.closeAutocomplete();

    const baseUrl = this.props.match.url;
    const queryParams = {
      q: this.state.searchText
    };
    const relativePath = qs.stringify(queryParams);
    const newUrl = baseUrl + "lt/search?" + relativePath;
    this.props.history.push(newUrl);
    ReactGA.event({
      category: 'engagement',
      action: 'search',
      label: this.state.searchText
    });
    ReactGA4.event(
      "search",
      {
        search_phrase: this.state.searchText
      }
    );

    /*this.setState({
      searchText: "",
    });*/

  }

  /*removeSearchText() {
    this.setState({
      searchText: "",
    })
  }*/

  closeAutocomplete = () => {
    this.setState({
      isShowAutocomplete: false
    })
  }
  showAutocomplete = () => {
    this.setState({
      isShowAutocomplete: true
    })
  }
  onFocusSearchInput = () => {
    this.showAutocomplete();
  }
  handleClickOutsideAutocomplete = (e) => {
    let clickedOutsideAutocomplete = this.autoCompleteWrapperRef && this.autoCompleteWrapperRef.current
      && !this.autoCompleteWrapperRef.current.contains(event.target);

    let clickedOutsideSearchInput = this.searchInputWrapperRef && this.searchInputWrapperRef.current
      && !this.searchInputWrapperRef.current.contains(event.target);

    if (clickedOutsideAutocomplete && clickedOutsideSearchInput) {
      this.closeAutocomplete();
    }

  }
  render() {
    let additionalClass = "";
    if(this.props.isMobile) {
      additionalClass = "searchMobile";
    }

    return (

      <div className = "SearchBox">
        <form className = "SearchBoxForm" onSubmit = {this.searchProducts}>
          <input type = "text"
            ref = {this.searchInputWrapperRef}
            className = {`search ${additionalClass}`}
            value = {this.state.searchText}
            onChange = {this.searchTextChanged}
            onFocus = {this.onFocusSearchInput}
            aria-label="Ieškoti"
          />
          <button type = "submit"
            className = 'searchSubmit'
            aria-label="Ieškoti prekių"
          >
            <BsSearch size = {22}  />
            <span>IEŠKOTI</span>
          </button>
        </form>
        {
          this.state.isShowAutocomplete && this.state.searchText != "" ?
          <Autocomplete
            forwardedRef={this.autoCompleteWrapperRef}
            searchText = {this.state.searchText}
            closeAutocomplete = {this.closeAutocomplete}
          />
          :
          null
        }


      </div>
    )
  }
}

export default withRouter(SearchBox);
