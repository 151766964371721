import { server, axiosInstance } from "../_config.js";
//import axios from 'axios';

function fetchRunningBox(onSuccess, onError) {
  axiosInstance.get(`${server}/MenuData/RunningBox`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchRunningBox;

// tekstas iš sirenitas.lt/valdymas
