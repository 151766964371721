import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function logout(onSuccess, onError) {
  axiosInstance.post(`${server}/Account/Logout`, {
    }, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default logout;

/*


*/
