import React from 'react';
//import SubCategoriesColumn from './SubCategoriesColumn.js';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import SubCategory from './SubCategory.js';

//import SubCategory from './SubCategory.js';
//import '../../scss/NavigationUpper/HeaderSecondary.scss';
//import { AddCategoryQueryParam } from '../tools/urlBuilder.js'


class RootCategory extends React.Component {

  constructor() {
    super();
    this.state = {
      isMegamenuOpened: false,
      isMouseOutsideItem: true
    };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps) {
    if(this.props.MainMenuIsHovered && !this.state.isMegamenuOpened
      && !this.state.isMouseOutsideItem) {
      this.onRootCategoryHovered();
    }
  }

  switchMegaMenuVisibility = (isMegamenuOpened, isMouseOutsideItem) => {
    this.setState({
      isMegamenuOpened: isMegamenuOpened,
      isMouseOutsideItem: isMouseOutsideItem
    });
  }

  /*splitToChunks(array, parts) {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
  }*/
  onRootCategoryHovered = () => {
    this.setState({
      isMegamenuOpened: true
    });
  }
  onMouseOverRootCategory = () => {
    this.setState({
      isMouseOutsideItem: false
    });
  }

  /*distributeCircles(circles, k) {
    // Sort the circles in non-increasing order based on their circleCount property
    circles.sort((a, b) => b.sub.length - a.sub.length);

    // Initialize the buckets
    const buckets = new Array(k).fill().map(() => []);

    // Distribute the circles among the buckets
    for (let circle of circles) {
      // Find the bucket with the minimum sum of circleCount
      let minSum = Infinity;
      let minSumIndex = 0;

      for (let i = 0; i < k; i++) {
        let sum = this.sumOfBucket(buckets[i]);
        if (sum < minSum) {
          minSum = sum;
          minSumIndex = i;
        }
      }

      // Add the circle to the bucket with the minimum sum
      buckets[minSumIndex].push(circle);
    }

    return buckets;
  }

  // Helper function to calculate the sum of circleCount in a bucket
  sumOfBucket(bucket) {
    return bucket.reduce((sum, circle) => sum + (circle.sub.length > 5 ? 5 : circle.sub.length) + 1, 0);
  }*/

  // Helper function to calculate the sum of circleCount in all buckets
  /*sumOfAllBuckets(buckets) {
    return buckets.reduce((sum, bucket) => sum + this.sumOfBucket(bucket), 0);
  }*/

  render() {
    /*console.log(this.distributeCircles(this.props.data.sub.slice(0), 5));
    console.log(this.splitToChunks(this.props.data.sub.slice(0), 5));*/

    return (
      <li
         onMouseEnter= { this.onMouseOverRootCategory}
         onMouseLeave={e => {
           this.setState({
             isMegamenuOpened: false,
             isMouseOutsideItem: true
           });
         }}>

        <Link to={{
          pathname: `/lt/products/${this.props.data.url}`/*,
          search: `?categoryId=${this.props.data.id}`*/
        }}
          onClick = {e => {
            this.switchMegaMenuVisibility(false, true);
          }} >
          <span>{this.props.data.name}</span>
        </Link>

        { this.state.isMegamenuOpened ?
        <div className = "megamenu">
          <div className = "subCategories">
              {this.props.data && this.props.data?.sub?.map((subCategory) => (
                <SubCategory
                  key={subCategory.id}
                  data = {subCategory}
                  switchMegaMenuVisibility = {this.switchMegaMenuVisibility}
                />
              ))}
          </div>
        </div> : null
        }
      </li>
    )
  }
}

function mapStateToProps(state) {
  return {
    MainMenuIsHovered: state.mainMenu.isHovered
  };
}

export default connect(mapStateToProps)(RootCategory);
