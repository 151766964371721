import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fullCategoriesTreeThunk } from 'Root/js/redux/thunks/fullCategoriesTreeThunk.js';
import 'Root/scss/CategoriesFilterMenu.scss';
//import { MdMenu } from '@react-icons/all-files/md/MdMenu'; //CgMenuGridR
//import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
//import { AiOutlineExpand } from '@react-icons/all-files/ai/AiOutlineExpand';
import { Link, withRouter } from "react-router-dom";
import { BiRightArrow } from '@react-icons/all-files/bi/BiRightArrow';


class CategoriesFilterMenu extends React.Component {
  constructor() {
    super();

    this.state = {
      stackCategories: [],
      stackSuperCategoriesNames: [],
      currentCategories: [],
      searchPhrase: "",
      hoveredCategoryId: null
    };

  }
  componentDidMount() {
    this.props.fullCategoriesTreeThunk();

    if(this.state.currentCategories.length == 0 && this.props.fullCategoriesTree) {
      this.setState({
        currentCategories: this.props.fullCategoriesTree
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(this.state.currentCategories.length == 0 && this.props.fullCategoriesTree
      && !prevProps.fullCategoriesTree) {
      this.setState({
        currentCategories: this.props.fullCategoriesTree
      });
    }
  }
  goToChildCategories = (category) => {
    if(!category.sub) {
      let url = `/lt/products/${category.url}`;
      this.props.history.push(url);
    } else {

      let stackCategories = this.state.stackCategories;
      stackCategories.push(this.state.currentCategories);

      let stackSuperCategoriesNames= this.state.stackSuperCategoriesNames;
      stackSuperCategoriesNames.push(category.name);

      let subCategories = category.sub;
      subCategories.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })

      this.setState({
        currentCategories: subCategories,
        stackCategories: stackCategories,
        stackSuperCategoriesNames: stackSuperCategoriesNames
      });
    }
  }
  goBackPrev = () => {
    let stackCategories = this.state.stackCategories;
    let currentCategories = stackCategories.pop();
    //let currentCategories = this.state.stackCategories.pop();
    let stackSuperCategoriesNames = this.state.stackSuperCategoriesNames;
    stackSuperCategoriesNames.pop();

    this.setState({
      currentCategories: currentCategories,
      stackCategories: stackCategories,
      stackSuperCategoriesNames: stackSuperCategoriesNames
    });
  }
  goBackStart = () => {
    this.setState({
      currentCategories: this.props.fullCategoriesTree,
      stackCategories: [],
      stackSuperCategoriesNames: []
    });
  }

  categoryHovered = (categoryId) => {
    this.setState({
      hoveredCategoryId: categoryId
    });
  }
  categoryUnhovered = () => {
    this.setState({
      hoveredCategoryId: null
    });
  }

  render() {
    //console.log(this.state.currentCategories);

    /*
    <div className = "title">
      <span>{this.state.stackSuperCategoriesNames.length > 0 ?
        this.state.stackSuperCategoriesNames.at(-1)
        :
        "Kategorijos"
      }</span>
    </div>

    */
    return (
      <div className = "CategoriesFilterMenu">
        <div className = "megamenuCategoriesList">


          {
            this.state.stackCategories.length == 0 ?
            <></>
            :
            <div className="item goBackBar">
              <div className="item goBackPrev" onClick = {this.goBackPrev}>
                <span>Grįžti</span>
              </div>
              <div className="item goBackStart" onClick = {this.goBackStart}>
                <span>Į pradžią</span>
              </div>
            </div>
          }

          <div className="categories">
            {/*UNIQUE ir perkelti i fetch vieta, pagal abecele*/
              this.state.currentCategories?.map((category) => (
                <div className="category" key = {category.id}
                  onMouseEnter={() => this.categoryHovered(category.id)}
                  onMouseLeave={() => this.categoryUnhovered()}
                >
                  <div className = "categoryIconContainer">
                    <img
                      src = {category.iconUrl}
                      width = {20}
                      height = {20}
                      alt = {category.name}
                    />
                  </div>
                  <div className="name">
                    <Link to={{
                        pathname: `/lt/products/${category.url}`
                      }}>
                      <span>{category.name}</span>
                    </Link>
                  </div>
                  <div className="controls">
                    <div className = "arrowIcon icon" title = "Rodyti subkategorijas">
                      {
                        category.sub &&
                        <RiArrowRightSLine size = {25}/>
                      }
                    </div>
                    {
                      this.state.hoveredCategoryId == category.id &&
                      category?.sub &&
                      <div className = "megamenuSidabar">
                        <div className = "megamenuSidabarContent">
                          {
                            category.sub?.map((subcategory, index) => (
                              <div className = "subCategory" key = {index}>
                                <div className = "categoryName">
                                  <BiRightArrow size = {15}/>
                                  <Link to={{
                                    pathname: `/lt/products/${subcategory.url}`
                                  }}>
                                    {subcategory.name}
                                  </Link>
                                </div>
                                {
                                  subcategory?.sub &&
                                  <div className = "subSubCategories">
                                  {
                                    subcategory?.sub?.map((subSubCat, index) => (
                                      <div className = "subSubCategory" key = {index}>
                                        <div className = "categoryName">
                                          <Link to={{
                                            pathname: `/lt/products/${subSubCat.url}`
                                          }}>
                                            {subSubCat.name}
                                          </Link>
                                        </div>
                                      </div>
                                    ))
                                  }
                                  </div>

                                }

                              </div>
                            ))
                          }
                        </div>
                      </div>
                    }

                  </div>
                </div>
              ))
            }

          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fullCategoriesTree: state.fullCategoriesTree.categories,
    fullCategoriesTreeFetching: state.fullCategoriesTree.isFetching
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      fullCategoriesTreeThunk: fullCategoriesTreeThunk
    }, dispatch);
}

//export default withRouter(CategoriesFilterMenu);
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CategoriesFilterMenu));
