import fetchFullCategories from 'Root/js/data-flow/fetchFullCategories.js';
import { fullCategoriesTreeStart, fullCategoriesTreeSuccess, fullCategoriesTreeError }
  from 'Root/js/redux/actions/fullCategoriesTreeAction.js';


export const fullCategoriesTreeThunk = () => {
  return (dispatch, getState) => {
    const state = getState().fullCategoriesTree;
    //console.log(state);
    if(!state.isFetching && !state.categories) {
      dispatch(fullCategoriesTreeStart);

      fetchFullCategories(
        data => fullCategoriesTreeOnSuccess(dispatch, data),
        response => fullCategoriesTreeOnError(dispatch, response)
      );
    }
  }
}

function fullCategoriesTreeOnSuccess (dispatch, data) {
  dispatch(fullCategoriesTreeSuccess(data));
}
function fullCategoriesTreeOnError(dispatch, response) {
  dispatch(fullCategoriesTreeError(response));
}
