export const allCategoriesListStart = () => ({
  type: 'ALL_CATEGOERIES_LIST_START'
})

export const allCategoriesListSuccess = (allCategoriesList) => ({
  type: 'ALL_CATEGOERIES_LIST_SUCCESS',
  allCategoriesList: allCategoriesList
})

export const allCategoriesListError = (error) => ({
  type: 'ALL_CATEGOERIES_LIST_ERROR',
  errorMessage: error
})
