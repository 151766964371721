import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function login(email, password, onSuccess, onError) {
  axiosInstance.post(`${server}/Account/Login`, {
      email: email,
      password: password
    }, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default login;

/*
Request
{"email":"test@gmail.com","password":"6cf58b6a-cca8-45fa-a0"}

Response
Jonas Petraitis
*/
