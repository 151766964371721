import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";


class CookieAcceptPopup extends React.Component {
  constructor() {
    super();

  }
  acceptPolicy = () => {
    const cookies = new Cookies();
    cookies.set('CookiePolicyAccept', '1', { path: '/', maxAge: 60 * 60 * 24 * 365 });
    this.forceUpdate();
  }
  render() {
    var showWindow = true;
    const cookies = new Cookies();
    var cookie = cookies.get('CookiePolicyAccept');
    if(cookie && cookie == 1) {
      showWindow = false;
    }
    return (
      <>
      {
        showWindow &&
        <div className = "CookieAcceptPopup">
          <div className = "text">
            <span>Mūsų svetainėje naudojami slapukai, siekiant užtikrinti jums teikiamų paslaugų kokybę ir geriausius pasiūlymus. Daugiau informacijos apie slapukus galite rasti: </span>
            <Link className = "cookieReference" to = "/lt/privatumo-politika">
              slapukų naudojimas
            </Link>.
          </div>
          <div className = "btn">
            <button onClick = {this.acceptPolicy}>Sutinku</button>
          </div>
        </div>
      }

      </>
    )
  }
}



export default CookieAcceptPopup
