import axios from 'axios';
//import { useDispatch } from 'react-redux';
//import { loginAction } from 'Root/js/redux/actions/loginAction.js';
import { loginSucceeded, loginFailed } from 'Root/js/redux/actions/loginAction.js';
import loginStatus from 'Root/js/data-flow/loginStatus.js'

let store

export const injectStore = _store => {
  store = _store
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    // Check the response for session expiration or other conditions
    checkLoginStatus();
    /*const applicationLogginStatus = store.getState().login.isLoggedIn;
    if (applicationLogginStatus != isLoggedInCookie(response)) {
      console.log("status needs to be changed");
      loginStatus(onLoginStatusSuccess, onLoginStatusError);
    }*/

    return response;
  },
  (error) => {

    checkLoginStatus();
    return Promise.reject(error);
  }
);

function checkLoginStatus() {
  const applicationLogginStatus = store.getState().login.isLoggedIn;
  if (applicationLogginStatus != isLoggedInCookie()) {
    //console.log("status needs to be changed");
    loginStatus(onLoginStatusSuccess, onLoginStatusError);
  }
}

// Function to check if the session has expired based on the response
function isLoggedInCookie() {
  //console.log(response);
  //const cookies = document.cookie;
  //console.log(cookies);
  //console.log('Cookies:', cookies);
  const isLoggedInCookie = document.cookie?.split(';').find((cookie) => cookie.trim().startsWith('isLoggedIn='));
  //console.log(isLoggedInCookie);
  //if(!isLoggedInCookie) return false;

  const isLoggedInValue = isLoggedInCookie?.split('=')[1];
  if(isLoggedInCookie) {
    if(isLoggedInValue === '1') {
      //console.log("logged out");
      return true;
    }
    else {
      return false;
    }
    /*else {
      console.log("logged out");
    }*/
  }
}

const onLoginStatusSuccess = (data) => {
  //const dispatch = useDispatch();
  //dispatch = store.dispatch(action)
  let name = data;
  store.dispatch(loginSucceeded(name));
  //this.props.loginSucceeded(name);
}

const onLoginStatusError = (response) => {
  //const dispatch = useDispatch();
  if(response && response.status == 401) {
    let error = response.data;
  }
  store.dispatch(loginFailed());
  //this.props.loginFailed();
}
/*let server = "http://194.135.94.54:6831";
server = "https://beta.sirenitas.lt/ext/api";*/
let server = process.env.API_URL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   // server = "https://localhost:44341";
   //server = "https://localhost:44341";
   server = '/api';
   //server = "https://localhost:44341";
   //server = "https://beta.sirenitas.lt/ext/api";
}

export {
  axiosInstance,
  server
};

/*
addToCartOnError 405
submitBuyerDataOnError 400
requestForQuotationOnError 400
remindPasswordLinkStatusOnError 410
loginOnError 401
remindPasswordOnError 400
changeUserDataOnError 400
*/
