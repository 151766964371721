import React from 'react';
import { Link } from "react-router-dom";
import { BsXSquareFill } from '@react-icons/all-files/bs/BsXSquareFill';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
//import removeCartProduct from '../data-flow/removeCartProduct.js'
//import { decreaseCartQuantity } from '../redux/actions/cartAction.js';
import { closeCartMenu } from '../redux/actions/cartMenuAction.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class CartMenuProduct extends React.Component {
  constructor() {
    super();
  }
  /*removeCartItem = () => {
    let cartItems = removeCartProduct(this.props.product.productId, this.props.product.isSupplierProduct,
      this.removeCartProductOnSuccess, this.removeCartProductOnError);
  }

  removeCartProductOnSuccess = (data) => {
    this.props.decreaseCartQuantity(this.props.product.quantityAdded);
    this.props.removeProduct();
  }
  removeCartProductOnError = (response) => {
  }*/

  render() {
    //console.log(this.props)
    let product = this.props.product;
    let productUrl = product.url;

    /*let productUrl = `/lt/product/${product.url}`;
    if(product.isSupplierProduct) {
      productUrl = `/lt/supplier-product/${product.url}`;
    }*/
    return (
      <div className = "cartProduct">
        <Link className = "image" to = {productUrl} onClick = {this.props.closeCartMenu}>
         <img src = {this.props.product.imageRef} alt = {product.name}/>
        </Link>
        <div className = "textData">
          <Link className = "title" to = {productUrl} onClick = {this.props.closeCartMenu}>
            {this.props.product.name}
          </Link>
          { this.props.product.isSupplierProduct ?
          <div className = "deliveryTime">
            Pristatymas per {this.props.product.deliveryTimeDays} dienas
          </div> : null
          }
          <div className = "quantityPrice">
            <div className = "quantityPriceItem">
              <span>{this.props.product.quantityAdded} {this.props.product.unit}</span>
            </div>
            <div className = "quantityPriceItem">
              <FaTimes/>
            </div>
            <div className = "quantityPriceItem">
              <span>{this.props.product.price.toFixed(2).replace('.', ',')}  €</span>
            </div>
          </div>
        </div>
        <div className = "actions" onClick = {() => this.props.removeCartItem(this.props.product)}>
          <BsXSquareFill
            className = "removeItem"
            size = {18}
            title = "Išimti iš krepšelio"
          />
        </div>

      </div>
    )
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeCartMenu: closeCartMenu
    }, dispatch);
}

export default connect(null, matchDispatchToProps)(CartMenuProduct)
