import React from 'react';
import '../../scss/Notifications/CartNotification.scss';
import { Link } from "react-router-dom";
/*import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';*/
import { BsCheckSquareFill } from '../../icons/BsCheckSquareFill';
//import { BsXSquareFill } from '@react-icons/all-files/bs/BsXSquareFill';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { GrClose } from '@react-icons/all-files/gr/GrClose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideAddedToCartNotification } from '../redux/actions/cartAction.js';


class ProductAddedToCartSuccessfully extends React.Component {
  finishTransition() {
    this.props.hideAddedToCartNotification();
  }

  render() {
    //console.log(this.props.isVisible);
    let product = this.props.productShown;
    let quantity = this.props.productQuantityShown;

    /*let priceToShow = 0;
    if(product.isSupplierProduct) {
      priceToShow = product.prices[0].value;
    } else {
      priceToShow = product.price;
    }*/

    return (
      <div className= {`CartNotification ${this.props.isVisible ? `show` : `hide`}`}
        onTransitionEnd = { () => this.props.hideAddedToCartNotification() }>

        <div className="title">
          <span>
            <BsCheckSquareFill className = "tick" size = {20}/>
            <span className = "text">Prekė sėkmingai įdėta į krepšelį !</span>
          </span>
        </div>
        <div className="details">
          {
          product &&
          <div className="productData">
              <div className="image">
                {
                product.imageRef &&
                <img src = { product.imageRef }/>
                }
              </div>
              <div className="textData">
                <div className="productTitle">
                  <span>{product.name}</span>
                </div>
              <div className="quantityPrice">
                <div className="quantity quantityPriceItem">
                  <span>{quantity} {product.unit}</span>
                </div>
                <div className="multiplySymbol quantityPriceItem">
                  <FaTimes/>
                </div>
                <div className="price quantityPriceItem">
                  <span>{product.price.toFixed(2).replace('.', ',')} €</span>
                </div>
              </div>
            </div>
          </div>
          }
          <div className="orderingData">
            <div className="viewCart orderingDataItem">
              <Link className="reviewCart link" to = "/lt/order/list">
                Peržiūrėti krepšelį
              </Link>
            </div>
            <div className="startBuying orderingDataItem">
              <Link className="makeOrder link" to = "/lt/order/order-info">
                Atlikti užsakymą
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    productShown: state.cart.productShown,
    productQuantityShown: state.cart.productQuantityShown
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      hideAddedToCartNotification: hideAddedToCartNotification
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ProductAddedToCartSuccessfully)
//export default ProductAddedToCartSuccessfully
