
const initialState = {
    isHovered: false
}

export const mainMenuReducer = function (state = initialState, action) {
  switch (action.type) {
    case "HOVER_MAINMENU":
      return {
        ...state,
        isHovered: true
      }
    case "UNHOVER_MAINMENU":
      return {
        ...state,
        isHovered: false
      }
    default:
      return state;
  }
};
