const initialState = {
    isLoggingIn: false,
    isFirstLoggingInFinished: false,
    isLoggedIn: false,
    personName: ""
}

export const loginReducer = function (state = initialState, action) {
  switch (action.type) {
    case "LOG_IN_PENDING":
      return {
        ...state,
        isLoggingIn: true,
        isLoggedIn: false
      }
    case "LOG_IN_SUCCESS":
      return {
        ...state,
        isLoggingIn: false,
        isFirstLoggingInFinished: true,
        isLoggedIn: true,
        personName: action.personName
      }
    case "LOG_IN_FAILED":
      return {
        ...state,
        isLoggingIn: false,
        isFirstLoggingInFinished: true,
        isLoggedIn: false,
        personName: ""
      }
    case "LOG_OUT_SUCCESS":
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        personName: ""
      }
    default:
      return state;
  }
};
