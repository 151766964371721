import React from 'react';
import '../../scss/MobileMenu/MobileMenu.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeMobileMenu } from '../redux/actions/mobileMenuAction.js';
//import CloseIcon from '../../icons/close.svg';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { AiOutlineExpand } from '@react-icons/all-files/ai/AiOutlineExpand';
import { Link, withRouter } from "react-router-dom";
//import fetchFullCategories from '../data-flow/fetchFullCategories.js'
import CategoriesFilterMenuMobile from 'Root/js/MobileMenu/CategoriesFilterMenuMobile.js';

class MobileMenu extends React.Component {

  constructor() {
    super();

    /*this.state = {

    }*/

  }

  closeMobileMenu = (event) => {
      //console.log(this.props);
      this.props.closeMobileMenu();
      //console.log(this.props);
  }

  render() {
    return (

      <div className = "MobileMenu">
        <div className = "mobileOverlay" onClick = {this.closeMobileMenu}></div>
        <CategoriesFilterMenuMobile closeMobileMenu = {this.props.closeMobileMenu} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuIsOpen: state.mobileMenu.isOpen
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeMobileMenu: closeMobileMenu
    }, dispatch);
}

//export default connect(mapStateToProps, matchDispatchToProps)(MobileMenu)
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(MobileMenu));
