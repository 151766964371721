import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import { allCategoriesListStart } from './../redux/actions/allCategoriesListAction.js';
import { allCategoriesListThunk } from 'Root/js/redux/thunks/allCategoriesListThunk.js';
import { fullCategoriesTreeThunk } from 'Root/js/redux/thunks/fullCategoriesTreeThunk.js';

import 'Root/scss/MobileMenu/CategoriesFilterMenuMobile.scss';
import { MdMenu } from '@react-icons/all-files/md/MdMenu'; //CgMenuGridR
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { AiOutlineExpand } from '@react-icons/all-files/ai/AiOutlineExpand';
import CloseIcon from 'Root/icons/close.svg';
//import fetchFullCategories from '../data-flow/fetchFullCategories.js';
//import fetchAllCategoriesList from '../data-flow/fetchAllCategoriesList.js';
import { Link, withRouter } from "react-router-dom";
//import fetchCategories from '../data-flow/fetchCategories.js'
//import Dropdown from './CategoriesFilter/Dropdown.js'

class CategoriesFilterMenuMobile extends React.Component {
  constructor() {
    super();

    this.state = {
      /*allCategories: [],
      allCategoriesList: [],*/
      stackCategories: [],
      stackSuperCategoriesNames: [],
      currentCategories: [],
      searchPhrase: ""
    };

  }
  componentDidMount() {
    //fetchFullCategories(this.categoriesOnSuccess, this.categoriesOnError);
    //fetchAllCategoriesList(this.allCategoriesListOnSuccess, this.allCategoriesListOnError);
    this.props.allCategoriesListThunk();
    this.props.fullCategoriesTreeThunk();

    if(this.state.currentCategories.length == 0 && this.props.fullCategoriesTree) {
      this.setState({
        currentCategories: this.props.fullCategoriesTree
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(this.state.currentCategories.length == 0 && this.props.fullCategoriesTree
      && !prevProps.fullCategoriesTree) {
      this.setState({
        currentCategories: this.props.fullCategoriesTree
      });
    }
  }

  goToChildCategories = (category) => {
    if(!category.sub) {
      let url = `/lt/products/${category.url}`;


      this.props.history.push(url);
      this.props.closeMobileMenu();

    } else {

      let stackCategories = this.state.stackCategories;
      stackCategories.push(this.state.currentCategories);

      let stackSuperCategoriesNames= this.state.stackSuperCategoriesNames;
      stackSuperCategoriesNames.push(category.name);

      let subCategories = category.sub;
      subCategories.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })

      this.setState({
        currentCategories: subCategories,
        stackCategories: stackCategories,
        stackSuperCategoriesNames: stackSuperCategoriesNames
      });
    }
  }
  goBackPrev = () => {
    let stackCategories = this.state.stackCategories;
    let currentCategories = stackCategories.pop();
    //let currentCategories = this.state.stackCategories.pop();
    let stackSuperCategoriesNames = this.state.stackSuperCategoriesNames;
    stackSuperCategoriesNames.pop();

    this.setState({
      currentCategories: currentCategories,
      stackCategories: stackCategories,
      stackSuperCategoriesNames: stackSuperCategoriesNames
    });
  }
  goBackStart = () => {
    this.setState({
      currentCategories: this.props.fullCategoriesTree,
      stackCategories: [],
      stackSuperCategoriesNames: []
    });
  }
  onSearchChange = (e) => {
    let searchPhrase = e.target.value;
    let categoriesToShow = [];
    if(searchPhrase) {
      let filteredCategories = this.props.allCategoriesList.filter(category => (
        category.name.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '')
        .includes(searchPhrase
          .toLowerCase()
          .normalize('NFKD').replace(/[^\w]/g, ''))
      ));
      categoriesToShow = filteredCategories;
    }
    else {
      categoriesToShow = this.props.fullCategoriesTree;
    }
    this.setState({
      searchPhrase: searchPhrase,
      currentCategories: categoriesToShow,
      stackCategories: [],
      stackSuperCategoriesNames: []
    });
  }
  render() {
    //console.log(this.props.allCategoriesList);
    return (
      <div className = "CategoriesFilterMenuMobile">
        <div className = "header">
          <div className = "title">

            {
              this.state.stackSuperCategoriesNames.length > 0 ?
              <span> {this.state.stackSuperCategoriesNames.at(-1)} </span>
              :
              <span className = "defaultTitleName">Kategorijos</span>
            }

          </div>
          <div className = "closeHeader" onClick = {this.props.closeMobileMenu}>
            <img className="closeIconImg" alt = "Uždaryti" src={CloseIcon}
            />
          </div>
        </div>
        {
          this.state.stackCategories.length == 0 ?
          <div className="item search">
            <div className="searchDiv">
              <input type = "text"
                value = {this.state.searchPhrase}
                onChange = {this.onSearchChange}
                placeholder = "Ieškoti pagal fragmentą"/>
              <BsSearch className="searchIcon" size = {25} />
            </div>
          </div>
          :
          <div className="item goBackBar">
            <div className="item goBackPrev" onClick = {this.goBackPrev}>
              <span>Grįžti</span>
            </div>
            <div className="item goBackStart" onClick = {this.goBackStart}>
              <span>Į pradžią</span>
            </div>
          </div>
        }

        <div className="categories">
          {/*UNIQUE ir perkelti i fetch vieta, pagal abecele*/
            this.state.currentCategories?.map((category) => (
              <div className="category" key = {category.id}>
              {
                category.iconUrl &&
                <div className = "categoryIconContainer">
                  <img
                    src = {category.iconUrl}
                    width = {20}
                    height = {20}
                    alt = {category.name}
                  />
                </div>
              }
                <div className="name" onClick = { () => {this.goToChildCategories(category)}}>
                  <span>{category.name}</span>
                </div>
                <div className="controls">
                  <div className = "expandIcon icon" title = "Rodyti prekes kategorijoje">
                    <Link to={{
                      pathname: `/lt/products/${category.url}`/*,
                      search: `?categoryId=${category.id}`*/
                    }}
                    onClick = {this.props.closeMobileMenu}
                    >
                      <AiOutlineExpand size = {28}/>
                    </Link>
                  </div>
                  <div className = "arrowIcon icon" title = "Rodyti subkategorijas"
                    onClick = { () => {this.goToChildCategories(category)}}>
                    {
                      category.sub &&
                      <RiArrowRightSLine size = {31}/>
                    }
                  </div>
                </div>
              </div>
            ))
          }

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    allCategoriesList: state.allCategoriesList.categories,
    allCategoriesListFetching: state.allCategoriesList.isFetching,
    fullCategoriesTree: state.fullCategoriesTree.categories,
    fullCategoriesTreeFetching: state.fullCategoriesTree.isFetching
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      allCategoriesListThunk: allCategoriesListThunk,
      fullCategoriesTreeThunk: fullCategoriesTreeThunk
    }, dispatch);
}

//export default withRouter(CategoriesFilterMenu);
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(CategoriesFilterMenuMobile));
