import React from 'react';
import '../../scss/Login/Login.scss';
import { FaExclamation } from '@react-icons/all-files/fa/FaExclamation';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { BiShow } from '@react-icons/all-files/bi/BiShow';
import { BiHide } from '@react-icons/all-files/bi/BiHide';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeRemindPasswordView } from './../redux/actions/remindPasswordViewAction.js';
import { loginSucceeded } from './../redux/actions/loginAction.js';
import remindPassword from '../data-flow/remindPassword.js'
import { showNotification } from '../redux/actions/notificationAction.js';

class RemindPassword extends React.Component {

constructor() {
  super();

  this.state = {
    isIncorrectUsernameOrPasswordMessage: false
  };
}

changeErrorMessageStatus(isErrorMessage) {
  this.setState({
    isIncorrectUsernameOrPasswordMessage: isErrorMessage
  });
}
showErrorMessage() {
  if(!this.state.isIncorrectUsernameOrPasswordMessage) {
    this.changeErrorMessageStatus(true);
  }
}
hideErrorMessage = () => {
  if(this.state.isIncorrectUsernameOrPasswordMessage) {
    this.changeErrorMessageStatus(false);
  }

}
submitForm = (e) => {
  e.preventDefault();

  let email = event.target.email.value;
  remindPassword(email, this.remindPasswordOnSuccess, this.remindPasswordOnError);
}

  remindPasswordOnSuccess = (data) => {
    this.props.showNotification("", "Slaptažodžio priminimo nuoroda išsiųsta nurodytu el. paštu");
    this.props.closeRemindPasswordView();
  }
  remindPasswordOnError = (response) => {
    if(response && response.status == 400) {
      //let error = response.data;
      this.showErrorMessage();
    }
  }

  render() {
    return (
      <div className="Login" ref = {this.props.wrapperRef}>
        <div className="header">
          <h2 className="title">Slaptažodžio atkūrimas</h2>
          <div className="close" onClick = {this.props.closeRemindPasswordView}>
            <FaTimes size = {40}/>
          </div>

        </div>

        <form className="body" onSubmit = {this.submitForm}>
          <div className="info">
            <span>
              Kai paspausite "Atkurti", Jums nurodytu el. pašto adresu bus atsiųstas laiškas su nuoroda, kurią paspaudę galėsite pasikeisti slaptažodį. Nuoroda bus aktyvi 6 val.
            </span>
          </div>
          <div className="credentials">
            {
              this.state.isIncorrectUsernameOrPasswordMessage ?
              <div className="errorMessage">
                <span>
                  <FaExclamation size = {16}/>
                  <span>Neteisingai nurodytas el. pašto adresas</span>
                </span>
              </div>
              :
              <div className="errorMessage"></div>
            }

            <div className="username credential">
              <span>El. paštas</span>
              <input
                type="text"
                name="email"
                autoComplete="email"
                onFocus = {this.hideErrorMessage}
                />
            </div>
          </div>

          <div className="loginButton">
            <button type = "submit">Atkurti</button>
          </div>
        </form>
      </div>
    )
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      closeRemindPasswordView: closeRemindPasswordView,
      loginSucceeded: loginSucceeded,
      showNotification: showNotification
    }, dispatch);
}

export default connect(null, matchDispatchToProps)(RemindPassword)
//export default Login
