
const initialState = {
    quantity: 0,
    showAddedToCartNotif: false,
    productShown: null,
    productQuantityShown: null
}

export const cartReducer = function (state = initialState, action) {
  switch (action.type) {
    case "SET_CART_QUANTITY":
      return {
        ...state,
        quantity: action.quantity
      }
    case "ADD_CART_QUANTITY":
      return {
        ...state,
        quantity: state.quantity + action.quantity
      }
    case "DECREASE_CART_QUANTITY":
      return {
        ...state,
        quantity: state.quantity - action.quantity
      }
    case "REMOVE_CART_QUANTITY":
      return {
        ...state,
        quantity: 0
      }
    case "SHOW_ADDEDTO_CART_NOTI":
      return {
        ...state,
        showAddedToCartNotif: true,
        productShown: action.productShown,
        productQuantityShown: action.productQuantityShown
      }
    case "HIDE_ADDEDTO_CART_NOTI":
      return {
        ...state,
        showAddedToCartNotif: false
      }
    default:
      return state;
  }
};
