import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function fetchWebsiteStatus(onSuccess, onError) {
    axiosInstance.get(`${server}/Website/IsWebsiteAvailable`,{
        withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      //console.log(error);
      onError(error.response);
    });
}

export default fetchWebsiteStatus;

/*
Response1: 200

Response2: 503
{"title":"Puslapis nepasiekimas !","message":"Vyksta prekių likučių atnaujinimas. Perkraukite puslapį po n minučių !"}

*/
