const initialState = {
    isOpen: false,
    product: null
}

export const rfqViewReducer = function (state = initialState, action) {
  switch (action.type) {
    case "OPEN_RFQ_VIEW":
      return {
        ...state,
        isOpen: true,
        product: action.product
      }
    case "CLOSE_RFQ_VIEW":
      return {
        ...state,
        isOpen: false,
        product: null
      }
    default:
      return state;
  }
};
