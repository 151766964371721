import React from 'react';
import { connect } from 'react-redux';
import { MdMenu } from '@react-icons/all-files/md/MdMenu'; //CgMenuGridR
import { BsSearch } from '@react-icons/all-files/bs/BsSearch';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { AiOutlineExpand } from '@react-icons/all-files/ai/AiOutlineExpand';
import { Link, withRouter } from "react-router-dom";
import CategoriesFilterMenu from 'Root/js/sharedComponents/CategoriesFilterMenu.js';

class CategoriesSearch extends React.Component {
  constructor() {
    super();

    this.state = {
      isShowCategoriesSearchMenu: false,
    };

  }
  componentDidMount() {
    /*fetchFullCategories(this.setCategoriesState);
    fetchAllCategoriesList(this.setAllCategoriesListState);*/
  }


  componentDidUpdate(prevProps) {
    //console.log(prevProps);
    if(prevProps.location.pathname != this.props.location.pathname) {
      this.setIsShowCategoriesSearchMenu(false);
      /*this.setState({
        isShowCategoriesSearchMenu: false
      });*/
    }
  }

  setIsShowCategoriesSearchMenu = (isShowCategoriesSearchMenu) => {
    this.setState({
      isShowCategoriesSearchMenu: isShowCategoriesSearchMenu
    });
  }
  onMouseEnterHandler = () => {
    this.setIsShowCategoriesSearchMenu(true);
    /*this.setState({
      isShowCategoriesSearchMenu: true
    });*/
  }
  onMouseLeaveHandler = () => {
    this.setIsShowCategoriesSearchMenu(false);
    /*this.setState({
      isShowCategoriesSearchMenu: false
    });*/
  }

  render() {
    return (
      <div className = "CategoriesSearch"
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler} >
        <div className = "categoriesFilter">
          <MdMenu size = {30}/>
          <span className = "categoriesTitle">KATEGORIJOS</span>
        </div>
        {
          this.state.isShowCategoriesSearchMenu && !this.props.isHomePageOpen &&
          <CategoriesFilterMenu />
        }


      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isHomePageOpen: state.homePage.isPageOpen
  };
}
//export default withRouter(CategoriesSearch);
export default withRouter(connect(mapStateToProps)(CategoriesSearch));
