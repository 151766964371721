import React /*, { lazy, Suspense }*/ from 'react';
import loadable from '@loadable/component'
import '../scss/fonts.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import Protected from "./Protected.js";

const AboutUs = loadable(() => import('./CompanyInformation/AboutUs.js'));
const Contacts = loadable(() => import('./CompanyInformation/Contacts.js'));
const Cooperation = loadable(() => import('./CompanyInformation/Cooperation.js'));
const PrivacyPolicy = loadable(() => import('./CompanyInformation/PrivacyPolicy.js'));
const HowToBuy = loadable(() => import('./CompanyInformation/HowToBuy.js'));
const PaymentInformation = loadable(() => import('./CompanyInformation/PaymentInformation.js'));
const Delivery = loadable(() => import('./CompanyInformation/Delivery.js'));
const PurchaseRules = loadable(() => import('./CompanyInformation/PurchaseRules.js'));
const WarrantyAndReturn = loadable(() => import('./CompanyInformation/WarrantyAndReturn.js'));
const HomePage = loadable(() => import('./Content/HomePage.js'));
const NewProducts = loadable(() => import('./Content/NewProducts.js'));
const ProductsDiv = loadable(() => import('./Content/ProductsDiv.js'));
const ProductPage = loadable(() => import('./Content/ProductPage.js'));
const ProductPageSupplier = loadable(() => import('./Content/ProductPageSupplier.js'));
const Order = loadable(() => import('./Content/Checkout/Order.js'));
const ChangePassword = loadable(() => import('./Login/ChangePassword.js'));
const Settings = loadable(() => import('./User/Settings.js'));
const PageNotFound = loadable(() => import('./PageNotFound.js'));
/*const AboutUs = lazy(() => import('./CompanyInformation/AboutUs.js'));
*/

class MainBody extends React.Component {

  componentDidMount() {
    let curLocation = this.props.location.pathname + this.props.location.search;
    //console.log(curLocation);
    this.setDefaultMetaDescription();
    ReactGA.initialize('UA-15072704-1');//  UA-228149253-1 (mano testinis)
    ReactGA.pageview(curLocation);
    //visitor.pageview(curLocation).send();
    //GA
    ReactGA4.initialize("G-0X2XGZ719B"); //  G-ZBGFYLFR6B (mano testinis)
    ReactGA4.send({ hitType: "pageview", page: curLocation, title: document.title });


  }

  componentDidUpdate(prevProps, prevState) {
    let prevLocation = prevProps.location.pathname + prevProps.location.search;
    let curLocation = this.props.location.pathname + this.props.location.search;
    if(prevLocation != curLocation) {
      //console.log(curLocation);
      this.setDefaultMetaDescription();
      ReactGA.pageview(curLocation);
      ReactGA4.send({ hitType: "pageview", page: curLocation, title: document.title });
      //visitor.pageview(curLocation).send();
      //GA
    }
  }

  setDefaultMetaDescription = () => {
    const newMetaDescription = "Mes siūlome elektronikos komponentus, nuotolinio valdymo pultus, buitinės technikos atsargines dalis, akumuliatorius.";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', newMetaDescription);
    }
  }

  render() {
    const { router, params, location, routes } = this.props;
    //console.log(location);  <Suspense fallback={<div>Loading...</div>}>
    //console.log(this.props); </Suspense>
    //console.log(this.props);

    /*<Redirect
      from="/lt/supplier-product/:id/:name"
      to="/lt/supplier-product/:id-:name"
    />

    <Redirect
      from="/lt/:data"
      to="/:data"
    />

    <Redirect
      from="/lt/lt/products/:id"
      to="/products?categoryId=:id"
    />
    */
    return (
      <div className="MainBody">

            <Switch>

              <Redirect
                from="/lt/news/3268-naujienos.html"
                to="/lt/naujienos"
              />
              <Redirect
                from="/lt/pages/53260-kontaktai.html"
                to="/lt/kontaktai"
              />
              <Redirect
                from="/lt/pages/3267-apie-mus.html"
                to="/lt/apie-mus"
              />
              <Redirect
                from="/lt/pages/3264-bendradarbiavimas.html"
                to="/lt/bendradarbiavimas"
              />
              <Redirect
                from="/lt/pages/53266-apmokejimas.html"
                to="/lt/apmokejimas"
              />
              <Redirect
                from="/lt/pages/53267-pristatymas.html"
                to="/lt/pristatymas"
              />
              <Redirect
                from="/lt/pages/53269-prekiu-garantija-ir-grazinimo-salygos.html"
                to="/lt/prekiu-garantija-ir-grazinimo-salygos"
              />
              <Redirect
                from="/lt/pages/53270-taisykles.html"
                to="/lt/apsipirkimo-taisykles"
              />
              <Redirect
                from="/lt/pages/53271-kaip-apsipirkti.html"
                to="/lt/kaip-apsipirkti"
              />
              <Redirect
                from="/lt/pages/337913-privatumo-politika.html"
                to="/lt/privatumo-politika"
              />



              <Route path="/lt/apie-mus">
                <AboutUs siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/kontaktai">
                <Contacts siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/bendradarbiavimas">
                <Cooperation siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/privatumo-politika">
                <PrivacyPolicy siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/kaip-apsipirkti">
                <HowToBuy siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/apmokejimas">
                <PaymentInformation siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/pristatymas">
                <Delivery siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/apsipirkimo-taisykles">
                <PurchaseRules siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/prekiu-garantija-ir-grazinimo-salygos">
                <WarrantyAndReturn siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/products/:id-:categoryName" >
                <ProductsDiv siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/products/:id-" >
                <ProductsDiv siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/products/:id" >
                <ProductsDiv siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/search" >
                <ProductsDiv siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/product/:id-:productName" >
                <ProductPage siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/product/:id-" >
                <ProductPage siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/product/:id" >
                <ProductPage siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/supplier-product/:id-:productName" >
                <ProductPageSupplier siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/supplier-product/:id-" >
                <ProductPageSupplier siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/supplier-product/:id" >
                <ProductPageSupplier siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/order" >
                <Order siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/ext/mail" >
                <ChangePassword siteTitle = {this.props.siteTitle} />
              </Route>
              <Route path="/lt/user/profile-settings" >
                <Protected isLoggedIn = {this.props.isLoggedIn || !this.props.isFirstLoggingInFinished}>
                  <Settings siteTitle = {this.props.siteTitle} />
                </Protected>
              </Route>
              <Route exact path="/lt/naujienos">
                <NewProducts siteTitle = {this.props.siteTitle} />
              </Route>
              <Route exact path="/lt">
                <HomePage siteTitle = {this.props.siteTitle} />
              </Route>
              <Route exact path="/">
                <HomePage siteTitle = {this.props.siteTitle} />
              </Route>
              <Route>
                <PageNotFound siteTitle = {this.props.siteTitle} />
              </Route>
            </Switch>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.login.isLoggedIn,
    isFirstLoggingInFinished: state.login.isFirstLoggingInFinished
  };
}
//export default withRouter(MainBody)
export default withRouter(connect(mapStateToProps)(MainBody));
