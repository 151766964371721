import React from 'react';
//import '../../scss/NavigationUpper/HeaderSecondary.scss';
import RootCategory from './RootCategory.js';
import fetchCategories from '../data-flow/fetchCategories.js'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hoverMainMenu, unhoverMainMenu } from '../redux/actions/mainMenuAction.js';

const hoverTimeOutMlseconds = 400;

class RootCategories extends React.Component {

  constructor() {
    super();

    this.state = {
      categories: [],
      mainMenuTimeOut: 0
    }
  }
  onRootCatsLoadSuccess = (data) => {
    this.setState({
      categories: data
    });
  }
  onRootCatsLoadError = (response) => {
    setTimeout(() => {
      fetchCategories(this.onRootCatsLoadSuccess, this.onRootCatsLoadError);
    }, 6000);
  }

  componentDidMount() {
    //console.log(this.state);
    fetchCategories(this.onRootCatsLoadSuccess, this.onRootCatsLoadError);
  }

  render() {
    return (
      <ul className = "menu"
        onMouseEnter = {e => {
          this.setState({
            mainMenuTimeOut: setTimeout(() =>
            {
              this.props.hoverMainMenu();
            }, hoverTimeOutMlseconds)
          })
        }}
        onMouseLeave = {e => {
          clearTimeout(this.state.mainMenuTimeOut);
          this.props.unhoverMainMenu();
        }}>
        {
          this.state.categories && this.state.categories?.map((category) => (
          <RootCategory key={category.id} data = {category} />
        ))
      }

      </ul>
    )
  }
}

function mapStateToProps(state) {
  return {
    isHovered: state.mainMenu.isHovered
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      hoverMainMenu: hoverMainMenu,
      unhoverMainMenu: unhoverMainMenu
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(RootCategories);
