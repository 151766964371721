
const initialState = {
    pending: false,
    products: [{key: 1, name: "aa"}, {key: 2, name: "bbb"}],
    error: null
}

export const productsReducer = function (state = initialState, action) {
  /*console.log("gggggggggg");
  console.log(state);
  console.log(action);*/
  switch (action.type) {
    case "FETCH_PRODUCTS_PENDING":
      return {
        ...state,
        pending: true
      }
    case "FETCH_PRODUCTS_SUCESS":
      return {
        ...state,
        pending: false,
        products: action.products
      }
    case "FETCH_PRODUCTS_ERROR":
      return {
        ...state,
        pending: false,
        error: action.error
      }
    default:
      return state;
  }
};
