const initialState = {
    isOpen: false
}

export const remindPasswordViewReducer = function (state = initialState, action) {
  switch (action.type) {
    case "OPEN_REMIND_PASS_VIEW":
      return {
        ...state,
        isOpen: true
      }
    case "CLOSE_REMIND_PASS_VIEW":
      return {
        ...state,
        isOpen: false
      }
    default:
      return state;
  }
};
