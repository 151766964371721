import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function fetchFullCategories(onSuccess, onError) {
  axiosInstance.get(`${server}/Categories/CategoriesWithSubcategoriesFull`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchFullCategories;

/*
Response:
visu lygiu kategorijos: K1 -> K2 -> K3 -> ... -> Kn (atvaizdavimui ir narsymui per telefona)

[{"id":"445845-kat-pav","name":"Energijos \u0161altiniai","sub": {"id": ...} }, {}, ...]

*/
