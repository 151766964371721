import React from 'react';
import '../scss/App.scss';
import "react-loading-skeleton/dist/skeleton.css";
import fetchWebsiteStatus from './data-flow/fetchWebsiteStatus.js';
import fetchCartQuantity from './data-flow/fetchCartQuantity.js';
import loginStatus from './data-flow/loginStatus.js'
import ErrorBoundary from './ErrorBoundary.js';
import NavigationUpper from './NavigationUpper/NavigationUpper.js';
import MobileMenu from './MobileMenu/MobileMenu.js';
import CartMenu from './CartMenu/CartMenu.js';
import Login from './Login/Login.js';
import RemindPassword from './Login/RemindPassword.js';
import RfqView from './Content/RfqView.js';
import ProductAddedToCartSuccessfully from './Notifications/ProductAddedToCartSuccessfully.js';
import Notification from './Notifications/Notification.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCartQuantity } from './redux/actions/cartAction.js';
import { closeLoginView } from './redux/actions/loginViewAction.js';
import { loginPending, loginSucceeded, loginFailed } from './redux/actions/loginAction.js';
import { closeRemindPasswordView } from './redux/actions/remindPasswordViewAction.js';
import { closeRfqView } from './redux/actions/rfqViewAction.js';
import { HashRouter, BrowserRouter, withRouter } from "react-router-dom"; // BrowserRouter
//import Products from './Content/Products.js';
import Footer from './Footer/Footer.js';
import MainBody from './MainBody.js';
import CookieAcceptPopup from './CookieAcceptPopup.js';
import WebsiteUnavailable from './WebsiteUnavailable.js';
import { hotjar } from 'react-hotjar';


let Router = BrowserRouter;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
   Router = HashRouter;
}

const siteTitle = " - UAB Sirenitas"

class App extends React.Component {
  constructor(){
    super();

    this.wrapperRef = React.createRef();

    this.state = {
      isWebsiteWorking: true,
      websiteError: null
    };
  }
  componentDidMount() {
    this.checkWebsiteStatus();
    document.addEventListener('mousedown', this.handleClickOutside);
    //fetchCartQuantity(this.props.addCartQuantity);
    fetchCartQuantity(this.fetchCartQuantityOnSuccess, this.fetchCartQuantityOnError);
    this.props.loginPending();
    loginStatus(this.onLoginStatusSuccess, this.onLoginStatusError);
    hotjar.initialize(3751094, 6);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevState.isWebsiteWorking && this.state.isWebsiteWorking) {
      window.location.reload(false);
    }
  }

  fetchCartQuantityOnSuccess = (data) => {
    let quantity = data;
    if(quantity > 0) {
      this.props.setCartQuantity(quantity);
    }
  }
  fetchCartQuantityOnError = (response) => {
  }


  onLoginStatusSuccess = (data) => {
    let name = data;
    this.props.loginSucceeded(name);
  }

  onLoginStatusError = (response) => {
    if(response && response.status == 401) {
      let error = response.data;
    }
    this.props.loginFailed();
  }

  checkWebsiteStatus() {

    fetchWebsiteStatus(this.onWebsiteAvailable, this.onWebsiteUnavailable);
    this.interval = setInterval(() => {

      fetchWebsiteStatus(this.onWebsiteAvailable, this.onWebsiteUnavailable);
    }, 8000);
  }
  onWebsiteAvailable = (data) => {
    //console.log("aaa");
    this.setState({
      isWebsiteWorking: true,
      websiteError: null
    });
  }
  onWebsiteUnavailable = (response) => {
    //Naudotojo sesija baigesi, perkelti i homepage, jei vyksta pirkimo procesui
    //console.log(response);
    if(response && response.status === 401) {
      let error = response.data;
      let relativeURLs = ["/lt/order/order-info", "/lt/order/delivery"]
      const currentURL = window.location.href;

      const containsRelativeURL = relativeURLs.some((relativeURL) =>
        currentURL.includes(relativeURL)
      );
      if(containsRelativeURL) {
        history.push("/lt");
      }
      return;
    }

    var error = {
      title: "Puslapis nepasiekiamas!",
      message: "Bandykite prisijungti vėliau"
    };

    if(response) { // && response.status === 503
      let error = response.data;
    }

    this.setState({
      isWebsiteWorking: false,
      websiteError: error
    });
  }

  handleClickOutside = (event) => {
    //console.log(this.wrapperRef.current);
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        this.props.closeLoginView();
        this.props.closeRemindPasswordView();
        this.props.closeRfqView();
    }
  }

  render() {
    //const { router, params, location, routes } = this.props
    //console.log(this.state.isWebsiteWorking);
    return (
      <div className="App">
        <Router>
          <NavigationUpper />
          <Notification />
          { this.props.mobileMenuIsOpen && <MobileMenu /> }
          <CartMenu />
          {
            this.props.loginViewIsOpen && !this.props.isLoggedIn &&
            <div className = 'webPageContentOverlay'>
              <Login wrapperRef={this.wrapperRef} />
            </div>
          }
          {
            this.props.remindPasswordViewIsOpen && !this.props.isLoggedIn &&
            <div className = 'webPageContentOverlay'>
              <RemindPassword wrapperRef={this.wrapperRef} />
            </div>
          }
          {
            this.props.rfqViewIsOpen &&
            <div className = 'webPageContentOverlay'>
              <RfqView wrapperRef={this.wrapperRef} />
            </div>
          }

          {
            !this.state.isWebsiteWorking &&
            <div className = 'webPageContentOverlay waitServer'>
              <WebsiteUnavailable error = {this.state.websiteError}  />
            </div>
          }

            <ProductAddedToCartSuccessfully isVisible = {this.props.showAddedToCartNotif } />

            <div className = "mainBody">
              <ErrorBoundary>
                <MainBody siteTitle = {siteTitle} />
              </ErrorBoundary>
            </div>
            <CookieAcceptPopup />
            <Footer />
        </Router>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    pending: state.products.pending,
    mobileMenuIsOpen: state.mobileMenu.isOpen,
    showAddedToCartNotif: state.cart.showAddedToCartNotif,
    loginViewIsOpen: state.loginView.isOpen,
    isLoggedIn: state.login.isLoggedIn,
    remindPasswordViewIsOpen: state.remindPasswordView.isOpen,
    rfqViewIsOpen: state.rfqView.isOpen
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
      setCartQuantity: setCartQuantity,
      closeLoginView: closeLoginView,
      loginPending: loginPending,
      loginSucceeded: loginSucceeded,
      loginFailed: loginFailed,
      closeRemindPasswordView: closeRemindPasswordView,
      closeRfqView: closeRfqView
    }, dispatch);
}

/*export default connect(mapStateToProps)(App);*/
export default connect(mapStateToProps, matchDispatchToProps)(App)
//export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));
/*export default compose(withRouter, connect(mapStateToProps)(App));*/
