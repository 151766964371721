import React from 'react';
import { Link } from "react-router-dom";
import '../../scss/Footer/Footer.scss';
/*import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';*/
class Footer extends React.Component {

  render() {
    return (
      <footer className="Footer" id="footer">
        <div className = "companyInformation">
          <div className = "onlineContactDetails">
            <div className = "data">
              <div className = "title">Internetinė prekyba</div>
              <div className = "phone">T: +370 647 89429 </div>
              <div className = "email">E: arunas1@sirenitas.lt</div>
            </div>
          </div>
          <div className = "webShopData">
            <div className = "onlinePurchasingDetails">
              <div className = "data">
                <div className = "linkItem">
                  <Link to = "/lt/kaip-apsipirkti">Kaip apsipirkti?</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/apmokejimas">Apmokėjimas</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/pristatymas">Pristatymas</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/apsipirkimo-taisykles">Apsipirkimo taisyklės</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/prekiu-garantija-ir-grazinimo-salygos">Prekių garantija ir grąžinimo sąlygos</Link>
                </div>
              </div>
            </div>
            <div className = "contacts">
              <div className = "data">
                <div className = "linkItem">
                  <Link to = "/lt/apie-mus">Apie mus</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/kontaktai">Kontaktai</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/bendradarbiavimas">Bendradarbiavimas</Link>
                </div>
                <div className = "linkItem">
                  <Link to = "/lt/privatumo-politika">Privatumo politika</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className = "copyright">
          <span>© UAB Sirenitas {new Date().getFullYear()}</span>
        </div>

      </footer>
    )
  }
}

export default Footer
