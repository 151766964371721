import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function requestForQuotation(productId, email, message, onSuccess, onError) {
  axiosInstance.post(`${server}/Products/RequestForQuotation`, {
      productId: productId,
      email: email,
      message: message
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default requestForQuotation;

/*
Request
{"productId":"5705-prekes-pavadinimas","email":"aaaa","message":"dasdas"}


Request2
{"productId":"5705-prekes-pavadinimas","email":"dsaadssd","message":"dsadasdsa"}

Response2: 400 ?
{"productId":null,"email":"Neteisingai įvestas email","message":"Neįvesta žinutė"}

*/
