import { server, axiosInstance } from "./_config.js"
import qs from 'query-string';

function fetchAutocomplete(phrase, onSuccess, onError) {
  const queryParams = {
     phrase: phrase
  }
  const queryParamsString = qs.stringify(queryParams);

  axiosInstance.get(`${server}/Products/Autocomplete?${queryParamsString}`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchAutocomplete;

/*
QueryParams
phrase=laidas

Response (url parametro gal nereik, nes yra id. Id formatas su pavadinimu, kuris yra url 553085-akumuliatorius-svino-12v-17-ah-pb-yuasa.html)
[{"id":20686,"code":"CABLE565","title":"\u003Cspan class=\u0027highlighted\u0027\u003ELaidas\u003C/span\u003E i\u0161 HDMI-2xDVI","price":0,"isAvailable":false,"url":"https://www.sirenitas.lt/lt/product/20686","imageName":"https://www.sirenitas.lt/get_image.php?size=75\u0026path=PRODUCTS\u0026img=prod_no_img_v1.png"},{"id":17105,"code":"SCART39 1.5","title":"\u003Cspan class=\u0027highlighted\u0027\u003ELaidas\u003C/span\u003E SCART-4RCA","price":2,"isAvailable":true,"url":"https://www.sirenitas.lt/lt/product/17105","imageName":"https://www.sirenitas.lt/get_image.php?size=80\u0026path=PRODUCTS\u0026img=22442__4656565.jpg"},{"id":60506,"code":"SCART33","title":"\u003Cspan class=\u0027highlighted\u0027\u003ELaidas\u003C/span\u003E SCART-2RCA","price":1.42,"isAvailable":true,"url":"https://www.sirenitas.lt/lt/product/60506","imageName":"https://www.sirenitas.lt/get_image.php?size=80\u0026path=PRODUCTS\u0026img=16859__3232326.jpg"},{"id":104069,"code":"SCART29","title":"\u003Cspan class=\u0027highlighted\u0027\u003ELaidas\u003C/span\u003E SCART29","price":0,"isAvailable":false,"url":"https://www.sirenitas.lt/lt/product/104069","imageName":"https://www.sirenitas.lt/get_image.php?size=75\u0026path=PRODUCTS\u0026img=prod_no_img_v1.png"},{"id":105736,"code":"HQSS2524-2.5*","title":"\u003Cspan class=\u0027highlighted\u0027\u003ELaidas\u003C/span\u003E SVHS-SVHS","price":0,"isAvailable":false,"url":"https://www.sirenitas.lt/lt/product/105736","imageName":"https://www.sirenitas.lt/get_image.php?size=75\u0026path=PRODUCTS\u0026img=prod_no_img_v1.png"}]

*/
