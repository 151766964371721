import React from 'react';
import { Link } from "react-router-dom";
//import '../../scss/NavigationUpper/HeaderSecondary.scss';
//import { AddCategoryQueryParam } from '../tools/urlBuilder.js'

export default class SubSubCategory extends React.Component {

  componentDidMount() {
    //console.log(this.props);pathname: `/lt/products/${this.props.data.id}`
    //AddCategoryQueryParam("/products", this.props.data.id)
  }

  render() {
    return (
      <li className = "subSubCat">
        <Link to={{
            pathname: `/lt/products/${this.props.data.url}`/*,
            search: `?categoryId=${this.props.data.id}`*/
          }}
          onClick = {e => {
            this.props.switchMegaMenuVisibility(false, true);
          }}
          >
          {this.props.data.name}
        </Link>

      </li>
    )
  }
}
