import { server, axiosInstance } from "./_config.js"
import qs from 'query-string';

function fetchCartQuantity(onSuccess, onError) {
  axiosInstance.get(`${server}/Products/GetCartQuantity`, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchCartQuantity;

/*
Visu prekiu krepselyje bendras kiekis (atvaizdavimui ekrano virsuje prie krepselio icon)

Response:
32

*/
