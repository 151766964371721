export const loginPending = () => ({
  type: 'LOG_IN_PENDING'
})
export const loginSucceeded = (personName) => ({
  type: 'LOG_IN_SUCCESS',
  personName: personName
})
export const loginFailed = () => ({
  type: 'LOG_IN_FAILED'
})
export const logoutSucceeded = () => ({
  type: 'LOG_OUT_SUCCESS'
})
