import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function remindPassword(email, onSuccess, onError) {
  axiosInstance.post(`${server}/Account/RemindPassword`, {
      email: email
    }, {
      withCredentials: false
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default remindPassword;

/*
Request
{"email":"test@gmail.com"}


Request2
{"email":"ddsdsd"}

Response2: 400
{"title":"Klaida !","message":"Nurodytas el. paštas neegzistuoja !"}
*/
