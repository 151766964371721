// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Root/fonts/Roboto-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Root/fonts/Roboto-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Roboto-Regular\";src:local(\"Roboto-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}@font-face{font-family:\"Roboto-Bold\";src:local(\"Roboto-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}", "",{"version":3,"sources":["webpack://./dev/scss/fonts.scss"],"names":[],"mappings":"AAAA,WACI,4BAAA,CACA,mFAAA,CAEJ,WACI,yBAAA,CACA,gFAAA","sourcesContent":["@font-face {\r\n    font-family: 'Roboto-Regular';\r\n    src: local('Roboto-Regular'), url('Root/fonts/Roboto-Regular.woff2') format('woff2');\r\n}\r\n@font-face {\r\n    font-family: 'Roboto-Bold';\r\n    src: local('Roboto-Bold'), url('Root/fonts/Roboto-Bold.woff2') format('woff2');\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
