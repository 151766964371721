import { server, axiosInstance } from "./_config.js"
import axios from 'axios';

function fetchCategories(onSuccess, onError) {
  axiosInstance.get(`${server}/Categories/CategoriesWithSubSubcategories`)
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default fetchCategories;




/*import { server } from "./_config.js"

function fetchCategories(setCategoriesState) {
  fetch(`${server}/Categories/CategoriesWithSubSubcategories`)
  .then(res => res.json())
  .then(res => {
    if(res.error) {
      throw(res.error);
    }
    setCategoriesState(res);
  })
}

export default fetchCategories;*/




/*
Response:
3 lygiu kategorijos: K1 -> K2 -> K3 (atvaizdavimui nav bar'ui virsuje)

[{"id":"445845-kat-pav","name":"Energijos \u0161altiniai","sub": {"id": ...} }, {}, ...]

*/
