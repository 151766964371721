import { server, axiosInstance } from "./_config.js"
//import axios from 'axios';

function removeCartProduct(productId, isSupplier, onSuccess, onError) {
  axiosInstance.post(`${server}/Products/RemoveCartProduct`, {
      productId: productId,
      isSupplier: isSupplier
    }, {
      withCredentials: true
    })
    .then(res => {
      onSuccess(res.data);
    })
    .catch(error => {
      onError(error.response);
    });
}

export default removeCartProduct;

/*
Request
{"productId":"8917","isSupplier":0}

Response
200

Request (error) ? 400, 422,.. ?
*/
